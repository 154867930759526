import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import Dialog from "@material-ui/core/Dialog";

import ModalCadastrar from "./ModalCadastrar";
import { ActionButton } from "../../../../components";
import { maybeCallback } from "../../../../_common/utils/fp";

function CadastrarLead({ exibirIcon, callback, organizacaoDisabled = false, initialFormValues = {}, ...props }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const close = lead => {
    setModalOpen(false);
    maybeCallback(callback)(lead);
  };
  return (
    <Fragment>
      {exibirIcon ? (
        <ActionButton
          type="menu"
          id="cadastrar"
          label={intl.formatMessage({ defaultMessage: "Adicionar Oportunidade" })}
          onClick={() => setModalOpen(true)}
          {...props}
        />
      ) : (
        <ActionButton
          type="text"
          id="cadastrar"
          color="primary"
          variant="contained"
          label={intl.formatMessage({ defaultMessage: "Cadastrar" })}
          onClick={() => setModalOpen(true)}
          {...props}
        />
      )}

      <Dialog open={modalOpen}>
        <ModalCadastrar
          {...props}
          onConfirm={close}
          onCancel={close}
          organizacaoDisabled={organizacaoDisabled}
          initialFormValues={initialFormValues}
        />
      </Dialog>
    </Fragment>
  );
}

CadastrarLead.propTypes = {
  exibirIcon: PropTypes.bool,
  organizacaoDisabled: PropTypes.bool,
  initialFormValues: PropTypes.object,
  callback: PropTypes.func,
};

export default CadastrarLead;
