import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";

import WhatsAppIcon from "../../../whatsapp/WhatsAppIcon";
import { newHsmMessage } from "../../../whatsapp/RegistrationRequest/services";

import { ActionButton } from "../../../../components";

function WhatsAppHsm({ lead }) {
  const hasEnabled = useSelector(state => state.whatsApp.habilitado);
  if (!hasEnabled) return null;

  const intl = useIntl();
  const handleOpenModalHsmWhatsApp = useCallback(() => newHsmMessage({ leadId: lead.id }), [lead]);

  return (
    <ActionButton
      id="btn-nova-mensagem-hsm"
      type="icon"
      tooltip={intl.formatMessage({ defaultMessage: "Enviar mensagem" })}
      onClick={handleOpenModalHsmWhatsApp}
      icon={<WhatsAppIcon color="currentColor" size={20} />}
      leads={lead}
    />
  );
}

WhatsAppHsm.propTypes = {
  lead: PropTypes.object,
};

export default WhatsAppHsm;
