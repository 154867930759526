import React, { useState } from "react";
import PropTypes from "prop-types";
import { MenuItem, Input, Select, Chip, withStyles } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import InputMask from "react-input-mask";
import cn from "classnames";

import { TIPO_CAMPO_PERSONALIZADO } from "../../../../_common/constantes";
import { Flex } from "../../../../_common";
import TextMaskNumber from "../../../../components/TextMaskNumber";

const MAX_LENGTH_DEFAULT = 8000;

function CampoPersonalizado({
  tipo,
  opcoes = [],
  field,
  form,
  value,
  onChange,
  formato,
  obrigatorio,
  unico,
  classes,
  leadId,
  printError,
  id,
  tamanhoMaximo,
  ...props
}) {
  const error = printError;
  const [valueEscolhaUnica, setValueEscolhaUnica] = useState(field?.value?.valores ? field.value.valores[0] : "");
  const handleChange = e => {
    form.setFieldValue(field.name, {
      ...field.value,
      ...(TIPO_CAMPO_PERSONALIZADO.isEscolha(tipo) ? { valores: [e.target.value] } : { valor: e.target.value }),
    });
    if (tipo === TIPO_CAMPO_PERSONALIZADO.ESCOLHA_UNICA) setValueEscolhaUnica(e.target.value);
  };

  if (formato && tipo === TIPO_CAMPO_PERSONALIZADO.TEXTO_CURTO)
    return (
      <Input
        error={error}
        value={field.value ? field.value.valor : ""}
        onChange={handleChange}
        disabled={form.isSubmitting}
        inputComponent={InputMask}
        inputProps={{ mask: formato }}
        type="text"
        {...props}
        id={String(id)}
      />
    );

  if (!formato && tipo === TIPO_CAMPO_PERSONALIZADO.TEXTO_CURTO)
    return (
      <Input
        error={error}
        value={field.value ? field.value.valor : ""}
        onChange={handleChange}
        disabled={form.isSubmitting}
        type="text"
        inputProps={{
          maxLength: tamanhoMaximo || MAX_LENGTH_DEFAULT,
        }}
        {...props}
        id={String(id)}
      />
    );

  if (!formato && tipo === TIPO_CAMPO_PERSONALIZADO.LINK)
    return (
      <Input
        error={error}
        value={field.value ? field.value.valor : ""}
        onChange={handleChange}
        disabled={form.isSubmitting}
        type="text"
        inputProps={{
          maxLength: tamanhoMaximo || MAX_LENGTH_DEFAULT,
        }}
        {...props}
        id={String(id)}
      />
    );

  if (tipo === TIPO_CAMPO_PERSONALIZADO.NUMERO)
    return (
      <Input
        error={error}
        value={field.value ? field.value.valor : ""}
        onChange={handleChange}
        disabled={form.isSubmitting}
        inputComponent={TextMaskNumber}
        inputProps={{
          length: MAX_LENGTH_DEFAULT,
        }}
        {...props}
        id={String(id)}
      />
    );

  if (tipo === TIPO_CAMPO_PERSONALIZADO.TEXTO_LONGO)
    return (
      <Input
        error={error}
        value={field.value ? field.value.valor : ""}
        onChange={handleChange}
        multiline={tipo === TIPO_CAMPO_PERSONALIZADO.TEXTO_LONGO}
        rows={4}
        disabled={form.isSubmitting}
        inputProps={{
          maxLength: tamanhoMaximo || MAX_LENGTH_DEFAULT,
        }}
        {...props}
        id={String(id)}
      />
    );

  if (tipo === TIPO_CAMPO_PERSONALIZADO.ESCOLHA_MULTIPLA)
    return (
      <Flex className={cn(classes.root, classes.multiplaEscolha)}>
        {opcoes.map(opcao => (
          <Chip
            key={opcao.id}
            disabled={form.isSubmitting}
            className={classes.chip}
            onClick={() => {
              form.setFieldValue(field.name, {
                ...field.value,
                valores:
                  field.value && field.value.valores && field.value.valores.includes(opcao.id)
                    ? field.value.valores.filter(fieldId => fieldId !== opcao.id)
                    : [...((field.value && field.value.valores) || []), opcao.id],
              });
            }}
            icon={field.value && field.value.valores && field.value.valores.includes(opcao.id) ? <Check /> : undefined}
            label={opcao.descricao}
          />
        ))}
      </Flex>
    );

  if (tipo === TIPO_CAMPO_PERSONALIZADO.ESCOLHA_UNICA)
    return (
      <Select
        error={error}
        value={valueEscolhaUnica}
        onChange={handleChange}
        disabled={form.isSubmitting}
        {...props}
        id={String(id)}
      >
        {!obrigatorio &&
          tipo === TIPO_CAMPO_PERSONALIZADO.ESCOLHA_UNICA && (
            <MenuItem value="">
              <em>-</em>
            </MenuItem>
          )}
        {opcoes.map(opcao => (
          <MenuItem key={opcao.id} value={opcao.id}>
            {opcao.descricao}
          </MenuItem>
        ))}
      </Select>
    );
}

CampoPersonalizado.propTypes = {
  unico: PropTypes.bool,
  leadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
  tipo: PropTypes.number,
  opcoes: PropTypes.array,
  field: PropTypes.object,
  form: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
  onChange: PropTypes.func,
  formato: PropTypes.string,
  obrigatorio: PropTypes.bool,
  classes: PropTypes.object,
  printError: PropTypes.bool,
  tamanho: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withStyles({
  root: { marginTop: 16 },
  chip: { marginRight: 16, marginBottom: 4 },
  multiplaEscolha: { flexWrap: "wrap" },
})(CampoPersonalizado);
