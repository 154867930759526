import React, { useContext } from "react";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Form } from "formik";
import { useIntl } from "react-intl";

import { SelectUnique } from "../../../../components";
import ListasContext from "../empresa/ListasContext";

function FormDores({ classes }) {
  const { dores } = useContext(ListasContext);
  const intl = useIntl();

  return (
    <Form>
      <SelectUnique
        label={intl.formatMessage({ defaultMessage: "Dores e objeções" })}
        emptyButtonLabel={intl.formatMessage({ defaultMessage: "Adicionar dor" })}
        name="dores"
        options={dores}
        hasPrincipal
        classes={{ row: classes.row }}
      />
    </Form>
  );
}

FormDores.propTypes = {
  classes: PropTypes.object,
};

export default withStyles({
  row: {
    margin: "18px 0",
  },
})(FormDores);
