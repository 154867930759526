import yup from "yup";

export default yup.object().shape({
  nome: yup.string().required("Campo obrigatório *"),
  cargo: yup.string(),
  email: yup.array(
    yup.object().shape({
      id: yup.number(),
      endereco: yup.string(),
      principal: yup.bool(),
    })
  ),
  mensageiros: yup.array(
    yup.object().shape({
      id: yup.number(),
      usuario: yup.string(),
      tipo: yup.object().shape({ id: yup.number() }),
    })
  ),
  telefones: yup.array(
    yup.object().shape({
      id: yup.number(),
      numero: yup.number(),
      principal: yup.bool(),
      tipo: yup.object().shape({ id: yup.number() }),
    })
  ),
  principal: yup.bool(),
});
