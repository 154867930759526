import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import axios from "axios";
import {
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { Formik, Field, ErrorMessage } from "formik";
import { TextField, RadioGroup } from "formik-material-ui";
import yup from "yup";
import moment from "moment";
import { get } from "dot-prop-immutable";
import useSWR, { mutate as globalMutate } from "swr";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import { useUsuario, useTipoPlanoSpotter } from "../../../../hooks";
import SelectUsuario from "../../../../components/SelectUsuario";
import EditProducts from "../produtos/EditProducts/EditProducts";
import createSnackAPIException from "../../../../_common/utils/snackbar/createSnackbarAPIException";
import { AjaxBlackout, DialogTitleClose } from "../../../../_common";
import { CREDITAR_VENDA } from "../../../../_common/constantes";
import { currencyToNumber } from "../../../../_common/utils/currency";
import { recarregarVenda } from "../../../base-leads/pipeline/actions";

const CAMPO_OBRIGATORIO = "Campo obrigatório *";

const validationSchema = yup.object().shape({
  creditarParaDataDaVenda: yup.string().required(CAMPO_OBRIGATORIO),
  usuarioVendedorId: yup.number().required(CAMPO_OBRIGATORIO),
  data: yup.string().required(CAMPO_OBRIGATORIO),
});

function ModalVender({ lead, funilId, callback = () => null }) {
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();
  const { enqueueSnackbar } = useSnackbar();
  const API_PRODUTOS_LEAD = `/api/pipeline/lead/produtos?id=${lead.id}`;
  const { tipoPerfil, id: usuario } = useUsuario();
  const { data: produtos, error: produtosError, mutate: mutateProdutos } = useSWR(API_PRODUTOS_LEAD);
  const { data: cfgProdutos } = useSWR(`/api/pipeline/ConfigsAvancadas/Outros/Buscar`);
  const isLoading = !produtos && !produtosError;
  const dispatch = useDispatch();

  const vender = React.useCallback(async ({ produtos: p, ...form }) => {
    const produtosList = p.map(({ desconto, valor, ...produto }) => ({
      ...produto,
      valor: typeof valor === "number" ? valor : currencyToNumber(valor || ""),
      desconto: typeof desconto === "number" ? desconto : currencyToNumber(desconto || ""),
    }));
    try {
      if (!cfgProdutos?.exigirProdutoNaVenda || (cfgProdutos?.exigirProdutoNaVenda && produtosList.length > 0)) {
        AjaxBlackout.Show();
        const { data: venda } = await axios.post("/api/pipeline/venda/cadastrar", {
          leadId: lead.id,
          funilId,
          creditarParaDataDaVenda: form.creditarParaDataDaVenda === "venda",
          listaProdutos: { produtos: produtosList },
          ...form,
        });
        venda.mensagensIntegracao.forEach(message => enqueueSnackbar(message));
        dispatch(recarregarVenda());
        callback(venda.vendaId);
      }
    } catch (err) {
      createSnackAPIException(err);
      mutateProdutos();
      globalMutate("/api/pipeline/produto/listar");
    } finally {
      AjaxBlackout.Hide();
    }
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        creditarParaDataDaVenda: CREDITAR_VENDA.DATA_VENDA.toString(),
        produtos: produtos || [],
        usuarioVendedorId: tipoPerfil === 6 ? usuario : lead.vendedorId,
        data: moment().format("YYYY-MM-DD"),
      }}
      onSubmit={vender}
      validationSchema={validationSchema}
    >
      {bag => (
        <Fragment>
          <DialogTitleClose onClose={() => callback(false)}>
            {intl.formatMessage({ defaultMessage: "Marcar lead como vendido" })}
          </DialogTitleClose>
          <DialogContent style={{ display: "flex", flexDirection: "column" }}>
            {tipoPerfil !== 6 && (
              <FormControl margin="dense">
                <InputLabel error={!!get(bag.errors, "usuarioVendedorId")} />
                <Field
                  value={lead.vendedorId}
                  id="modalVender-selecionarUsuario"
                  name="usuarioVendedorId"
                  component={SelectUsuario}
                />
              </FormControl>
            )}

            <Field
              id="modalVender-selecionarDataVenda"
              name="data"
              type="date"
              component={TextField}
              label={intl.formatMessage({ defaultMessage: "Data da venda *" })}
              InputLabelProps={{ shrink: true }}
              margin="dense"
            />
            {isSpotterFull && (
              <>
                <Field name="creditarParaDataDaVenda" component={RadioGroup}>
                  <FormControlLabel
                    id="modalVender-creditarParaDataVenda"
                    value={CREDITAR_VENDA.DATA_VENDA.toString()}
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      defaultMessage: "Creditar pontuação para data de venda (data indicada acima)",
                    })}
                  />
                  <FormControlLabel
                    id="modalVender-creditarParaMesAtual"
                    value={CREDITAR_VENDA.MES_ATUAL.toString()}
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({
                      defaultMessage: "Creditar pontuação para o mês atual (independente da data de venda)",
                    })}
                  />
                </Field>
                <EditProducts lead={lead} isLoading={isLoading} cfgProdutos={cfgProdutos} />
              </>
            )}

            <ErrorMessage name="produtos">
              {message => <FormLabel error>{Array.isArray(message) ? "" : message}</FormLabel>}
            </ErrorMessage>
          </DialogContent>
          <DialogActions>
            <Button id="modalVender-cancelar" onClick={() => callback(false)}>
              {intl.formatMessage({ defaultMessage: "Cancelar" })}
            </Button>
            <Button id="modalVender-vender" onClick={bag.handleSubmit} color="primary">
              {intl.formatMessage({ defaultMessage: "Vender" })}
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Formik>
  );
}

ModalVender.propTypes = {
  lead: PropTypes.object,
  funilId: PropTypes.number,
  callback: PropTypes.func,
};

export default ModalVender;
