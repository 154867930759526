import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@material-ui/core";
import { useIntl } from "react-intl";

import { InfoItem, Tipografia } from "../../../../_common";

export default function CamposPersonalizados({ campos = [] }) {
  const intl = useIntl();
  if (!campos?.length) {
    return (
      <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
        {intl.formatMessage({ defaultMessage: "Nenhum campo personalizado cadastrado" })}
      </Tipografia>
    );
  }
  return campos.map(
    campo =>
      [
        <InfoItem key={campo.id} label={campo.nome} value={campo.valor || "-"} />,
        <InfoItem key={campo.id} label={campo.nome} value={campo.valor || "-"} />,
        <InfoItem key={campo.id} label={campo.nome} value={campo.valor || "-"} />,
        <InfoItem
          key={campo.id}
          label={campo.nome}
          value={
            campo.valores && campo.valores[0] && campo.opcoes
              ? campo.opcoes.find(({ id: idC }) => idC === campo.valores[0]).descricao
              : "-"
          }
        />,
        <InfoItem
          key={campo.id}
          label={campo.nome}
          value={
            campo.valores && campo.valores.length && campo.opcoes
              ? campo.opcoes
                  .filter(({ id: idC }) => campo.valores.includes(idC))
                  .map(({ descricao, id: idC }) => <Chip style={{ marginRight: 8 }} key={idC} label={descricao} />)
              : "-"
          }
        />,
        <InfoItem
          key={campo.id}
          label={campo.nome}
          value={campo.valor || "-"}
          link={campo.valor !== undefined && campo.valor !== "" && campo.valor !== "-"}
        />,
      ][campo.tipo]
  );
}

CamposPersonalizados.propTypes = {
  classes: PropTypes.object,
  campos: PropTypes.array,
};
