import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import AttachMoney from "@material-ui/icons/AttachMoney";
import useSWR from "swr";
import { useIntl } from "react-intl";
import ModalVender from "./ModalVender";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { useUsuario, useSelectPermission, useTipoPlanoSpotter } from "../../../../hooks";
import { PERMISSAO_ETAPA, ACCESS_LEVEL } from "../../../../_common/constantes";
import usePermissaoEtapa from "../../../base-leads/pipeline/components/usePermissaoEtapa";

Vender.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
    situacao: PropTypes.number,
    etapa: PropTypes.shape({
      posVenda: PropTypes.bool,
      id: PropTypes.number.isRequired,
      funil: PropTypes.object,
    }),
    funil: PropTypes.shape({
      possuiGateVenda: PropTypes.bool,
    }),
  }),
};

function Vender({ callback = x => x, children, lead = {}, ...props }) {
  const [modalOpen, setModalOpen] = useState(false);
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();
  const permissaoNaEtapa = usePermissaoEtapa(lead.etapa);
  const { data: possuiVendaNoCiclo } = useSWR(() => `/api/pipeline/venda/possuiVendaNoCiclo?leadId=${lead.id}`);
  const { isGerente } = useUsuario();
  const venderLeadPermission = useSelectPermission(permissions.MARCAR_VENDIDO);
  const hasPermission =
    isGerente || permissaoNaEtapa === PERMISSAO_ETAPA.INTERAGIR || venderLeadPermission !== ACCESS_LEVEL.NONE;
  const etapaPossuiGateVenda = lead.funil?.possuiGateVenda || lead.etapa?.funil?.possuiGateVenda;

  if (!hasPermission || possuiVendaNoCiclo || lead.etapa?.posVenda || !lead.situacao || !etapaPossuiGateVenda)
    return null;

  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  return (
    <Fragment>
      <ActionButton
        id="btnVender"
        type="menu"
        icon={<AttachMoney />}
        label={intl.formatMessage({ defaultMessage: "Marcar como vendido" })}
        tooltip={intl.formatMessage({ defaultMessage: "Vender" })}
        onClick={() => setModalOpen(true)}
        claim={permissions.MARCAR_VENDIDO}
        leads={lead}
        {...props}
      />
      <Dialog fullWidth maxWidth={isSpotterFull ? "lg" : "sm"} open={modalOpen} onClose={() => close(false)}>
        <ModalVender lead={lead} funilId={lead.etapa?.funil?.id} {...props} callback={close} />
      </Dialog>
    </Fragment>
  );
}

export default Vender;
