import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { Button, DialogContent, DialogActions, Typography, withStyles } from "@material-ui/core";
import axios from "axios";
import { useIntl } from "react-intl";

import { AjaxBlackout, createSnackbar } from "../../../../_common";
import createSnackAPIException from "../../../../_common/utils/snackbar/createSnackbarAPIException";
import LeadSelectOrganizacao from "../SelectOrganizacao/SelectOrganizacao";

const INITIAL_VALUES = { organizacao: { id: null, nome: "" } };

function VincularOrganizacaoModal({ ids, onCancel = x => x, onConfirm = x => x, filtrosAplicados, classes }) {
  const intl = useIntl();

  async function onSubmit({ organizacao }, { setSubmitting }) {
    setSubmitting(true);
    AjaxBlackout.Show();
    try {
      await axios.post("/api/pipeline/Lead/VincularLeadsOrganizacao", {
        organizacaoId: organizacao?.id,
        leadIds: ids,
        filtros: filtrosAplicados,
      });
      createSnackbar(intl.formatMessage({ defaultMessage: "Organização vinculada com sucesso." }));
      onConfirm(true);
    } catch (err) {
      createSnackAPIException(err);
      setSubmitting(false);
      onConfirm(false);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      onReset={() => onCancel()}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ isSubmitting, handleReset, handleSubmit, errors }) => (
        <Form>
          <DialogContent classes={{ root: classes.dialogContentRoot }}>
            <Typography>
              {intl.formatMessage({
                defaultMessage:
                  "Selecione uma Empresa/Organização para vincular seus Leads/Oportunidades. Leads/Oportunidades já vinculados não serão processados.",
              })}
            </Typography>
            <Field
              fullWidth
              name="organizacao"
              component={LeadSelectOrganizacao}
              inputProps={{ maxLength: 255 }}
              disabled={isSubmitting}
              freeSolo
              required
              validate={val => (!val?.id ? intl.formatMessage({ defaultMessage: "Selecione um item da lista *" }) : "")}
              hasScroll
              error={errors.organizacao}
              paperMaxHeight="200px"
              showEmpty={false}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={isSubmitting} onClick={handleReset}>
              {intl.formatMessage({ defaultMessage: "Cancelar" })}
            </Button>
            <Button disabled={isSubmitting} onClick={handleSubmit} color="primary">
              {intl.formatMessage({ defaultMessage: "Confirmar" })}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

VincularOrganizacaoModal.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  filtrosAplicados: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default withStyles({
  dialogContentRoot: {
    overflowY: "visible",
  },
})(VincularOrganizacaoModal);
