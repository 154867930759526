import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import useSWR, { mutate } from "swr";
import { TextField } from "formik-material-ui";
import { Typography, FormControlLabel, Radio, withStyles, IconButton } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { Field, Form } from "formik";
import axios from "axios";

import { createDialogConfirm, createSnackbar, createSnackbarAPIException, Flex } from "../../../../_common";
import SelectBasesLegais from "../../../../components/SelectBasesLegais";
import Telefones from "../Telefones";
import Emails from "../Emails";
import Mensageiros from "../Mensageiros";
import BloquearContato from "./components/BloquearContato";
import LeadContatosSelectCargo from "./SelectCargo/SelectCargo";

function FormContato({
  values,
  setValues,
  contato,
  lead,
  leadId,
  exigirNomeTelefone = false,
  cadastro = false,
  onClose,
  isSubmitting,
  classes,
}) {
  const intl = useIntl();
  const { data: protecaoDados } = useSWR("/api/pipeline/protecaoDados/buscar");

  const canRemove = !exigirNomeTelefone;

  const mostrarBloquearContato = dadosContato =>
    !(!dadosContato.emails[0]?.id && !dadosContato.telefones[0]?.id && !dadosContato.mensageiros[0]?.id);

  async function remove(dadosContato) {
    try {
      await axios.post(`/api/pipeline/lead/contato/remover?id=${leadId}&contatoId=${dadosContato.id}`);
      createSnackbar(intl.formatMessage({ defaultMessage: "Alterações salvas com sucesso." }));
      mutate(`/api/pipeline/lead/contatos?id=${leadId}`);
      onClose(false);
    } catch (err) {
      createSnackbarAPIException(err);
    }
  }

  function confirm(e, dadosContato) {
    e.stopPropagation();
    createDialogConfirm({
      callback: res => res && remove(dadosContato),
      title: intl.formatMessage({ defaultMessage: "Excluir contato" }),
      text: intl.formatMessage({ defaultMessage: "Deseja excluir esse contato? Essa ação não pode ser desfeita." }),
      acceptLabel: intl.formatMessage({ defaultMessage: "Excluir" }),
      tamanhoMaximo: "sm",
      fullWidth: true,
    });
  }

  return (
    <Form>
      <div className={classes.contatoWrapper} data-scroll-anchor="contato">
        {!cadastro && (
          <Flex justifyContent="space-between" alignItems="center" className={classes.name}>
            <Typography variant="subtitle1">
              {intl.formatMessage({ defaultMessage: "Contato" })} {values.contato.nome}
            </Typography>
            <Flex alignItems="center">
              {mostrarBloquearContato(values.contato) && <BloquearContato id={values.contato.id} />}

              {canRemove && (
                <IconButton
                  disabled={isSubmitting}
                  title={intl.formatMessage({
                    defaultMessage: "Remover Contato",
                  })}
                  onClick={e => confirm(e, values.contato)}
                >
                  <Delete />
                </IconButton>
              )}
            </Flex>
          </Flex>
        )}
        <div className={classes.formWrapper}>
          <Flex className={classes.row}>
            <Field
              fullWidth
              name="contato.nome"
              label={intl.formatMessage({
                defaultMessage: "Nome do Contato",
              })}
              component={TextField}
              validate={e =>
                e && e.trim()
                  ? undefined
                  : intl.formatMessage({
                      defaultMessage: "Campo obrigatório *",
                    })
              }
              inputProps={{ maxLength: 50 }}
            />
          </Flex>
          <Flex className={classes.row}>
            <Field
              fullWidth
              name="contato.cargoObj"
              component={LeadContatosSelectCargo}
              inputProps={{ maxLength: 50 }}
              disabled={isSubmitting}
              contato={contato}
              freeSolo
            />
          </Flex>
          <Telefones name="contato.telefones" requiredOne={contato.principal && exigirNomeTelefone} lead={lead} />
          <Emails name="contato.emails" lead={lead} />
          <Mensageiros name="contato.mensageiros" lead={lead} />

          {protecaoDados && (
            <>
              <SelectBasesLegais
                values={values.contato}
                fieldNames={{
                  coletaDados: `contato.coletaDados`,
                  envioComunicacao: `contato.envioComunicacao`,
                  dadosVoz: `contato.dadosVoz`,
                }}
              />
            </>
          )}
          <Flex className={classes.row}>
            <FormControlLabel
              control={
                <Radio
                  name="contato-principal"
                  color="primary"
                  checked={values.contato.principal}
                  disabled={isSubmitting}
                  onChange={() =>
                    setValues({
                      ...values,
                      contato: { ...values.contato, principal: true },
                    })
                  }
                />
              }
              label={intl.formatMessage({ defaultMessage: "Este é o contato principal" })}
            />
          </Flex>
        </div>
      </div>
    </Form>
  );
}

FormContato.propTypes = {
  contato: PropTypes.object,
  /** Determina a obrigatoriedade do contato principal ter pelo menos um número de telefone cadastrado */
  exigirNomeTelefone: PropTypes.bool,
  cadastro: PropTypes.bool,
  lead: PropTypes.object,
  leadId: PropTypes.number,
  onClose: PropTypes.func,
  values: PropTypes.object,
  setValues: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default withStyles({
  row: {
    width: "calc(100% - 144px)",
  },
  italic: {
    fontStyle: "italic",
  },
  normal: {
    fontStyle: "normal",
  },
  contatoWrapper: {
    display: "flex",
    flexDirection: "column",
    "&:not(:first-child)": {
      paddingTop: 24,
    },
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 24,
  },
  addButton: {
    marginTop: 12,
  },
  name: {
    height: 32,
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
})(FormContato);
