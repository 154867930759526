import React from "react";
import { defineMessage, useIntl } from "react-intl";
import { withStyles, Typography, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import cn from "classnames";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";

import { Flex, createSnackbar } from "../../../../../../_common";
import { copyToClipboard } from "../../../../../../_common/utils/string";
import {
  AppearIn,
  Facebook,
  GoToMeeting,
  Hangouts,
  Instagram,
  LinkedIn,
  Skype,
  Twitter,
  TikTok,
  WebeX,
} from "../../../../../../_common/icones";

const ENUM_MENSAGEIRO = {
  Appearin: 1,
  Facebook: 8,
  GoToMeeting: 2,
  Hangouts: 3,
  Instagram: 9,
  LinkedIn: 7,
  Skype: 4,
  Twitter: 10,
  TikTok: 11,
  WebEX: 5,
  Outros: 6,
};

const mensageiroComponents = {
  [ENUM_MENSAGEIRO.Appearin]: AppearIn,
  [ENUM_MENSAGEIRO.Facebook]: Facebook,
  [ENUM_MENSAGEIRO.GoToMeeting]: GoToMeeting,
  [ENUM_MENSAGEIRO.Hangouts]: Hangouts,
  [ENUM_MENSAGEIRO.Instagram]: Instagram,
  [ENUM_MENSAGEIRO.LinkedIn]: LinkedIn,
  [ENUM_MENSAGEIRO.Skype]: Skype,
  [ENUM_MENSAGEIRO.Twitter]: Twitter,
  [ENUM_MENSAGEIRO.TikTok]: TikTok,
  [ENUM_MENSAGEIRO.WebEX]: WebeX,
  [ENUM_MENSAGEIRO.Outros]: MoreHoriz,
};

function copiar(value) {
  copyToClipboard(value);
  createSnackbar(defineMessage({ defaultMessage: "Copiado" }));
}
function RowMensageiroNovo({ mensageiro, classes }) {
  const intl = useIntl();
  if (!mensageiro) {
    return null;
  }

  const MensageiroIcon = mensageiroComponents[mensageiro.tipo.id];

  const montarClasse = principal => {
    if (principal) return cn(classes.icone, classes.principal);
    return cn(classes.icone, classes.secundario);
  };

  return (
    <Flex justifyContent="flex-start" alignItems="center" className={cn(classes.root, classes.actions)}>
      {MensageiroIcon && (
        <Tooltip title={mensageiro.principal ? intl.formatMessage({ defaultMessage: "Contato principal" }) : ""}>
          <MensageiroIcon className={montarClasse(mensageiro.principal)} />
        </Tooltip>
      )}
      <Typography className={cn(classes.label, classes.principal)}>{mensageiro.usuario}</Typography>
      <Flex className={classes.hide}>
        <FileCopyOutlined
          onClick={() => copiar(mensageiro.usuario)}
          className={classes.pointer}
          fontSize="small"
          color="primary"
        />
      </Flex>
    </Flex>
  );
}

RowMensageiroNovo.propTypes = {
  mensageiro: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles({
  root: {
    marginBottom: 8,
  },
  icone: { marginRight: 4 },
  label: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20.02px",
    letterSpacing: "0.17px",
    textDecoration: "none",
    textOverflow: "ellipsis",
    width: "80%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "&:hover": {
      textDecoration: "none",
    },
  },
  principal: {
    color: "#457AB7!important",
    "&:hover": {
      color: "#457AB7!important",
    },
  },
  secundario: {
    color: "rgba(0, 0, 0, 0.38)!important",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.38)!important",
    },
  },
  actions: {
    "&:hover $hide": {
      visibility: "visible",
    },
  },
  hide: {
    visibility: "hidden",
  },
  pointer: {
    cursor: "pointer",
  },
})(RowMensageiroNovo);
