import { defineMessages } from "react-intl";
import axios from "axios";

import { AjaxBlackout, createDialogConfirm } from "../../../_common";
import createSnackbarAPIException from "../../../_common/utils/snackbar/createSnackbarAPIException";

const messages = defineMessages({
  titleConcluir: { defaultMessage: "Concluir reunião" },
});

export default function concluirAgendamento(reuniaoId, leadId, acaoConclusao, textConcluir, onConfirm) {
  async function concluir() {
    AjaxBlackout.Show();
    try {
      await axios.post(
        `/api/pipeline/reuniao/concluir?reuniaoId=${reuniaoId}&leadId=${leadId}&acaoConclusao=${acaoConclusao}`
      );
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      AjaxBlackout.Hide();
      onConfirm();
    }
  }

  return createDialogConfirm({
    dialogProps: { maxWidth: "sm" },
    title: messages.titleConcluir,
    text: textConcluir,
    callback: confirmed => confirmed && concluir(),
  });
}
