import React, { useState } from "react";
import { withStyles, Typography, Menu, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import cn from "classnames";

import { Flex } from "../../../../../../_common";
import { GppGoodOutlined, GppMaybeOutlined, GppBadOutlined } from "../../../../../../_common/icones";
import { LABELS_BASE_LEGAL } from "../../../../../../_common/constantes";

const ENUM_NIVEL_LGPD = {
  Nenhum: 0,
  Algum: 1,
  Todos: 2,
};

function MenuLGPD({ coletaDados, envioComunicacao, dadosVoz, classes }) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);

  let nivelLGPD = ENUM_NIVEL_LGPD.Nenhum;
  if (coletaDados > ENUM_NIVEL_LGPD.Nenhum || envioComunicacao > ENUM_NIVEL_LGPD.Nenhum || dadosVoz)
    nivelLGPD = ENUM_NIVEL_LGPD.Algum;
  if (coletaDados > ENUM_NIVEL_LGPD.Nenhum && envioComunicacao > ENUM_NIVEL_LGPD.Nenhum && dadosVoz)
    nivelLGPD = ENUM_NIVEL_LGPD.Todos;

  const menuLGPD = event => {
    if (anchorEl !== null) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };

  const retornaLabel = itemLGPD => {
    if (itemLGPD === undefined || itemLGPD === ENUM_NIVEL_LGPD.Nenhum)
      return intl.formatMessage({ defaultMessage: "Não informado" });
    return LABELS_BASE_LEGAL[itemLGPD]?.defaultMessage;
  };

  return (
    <Flex alignItems="center" onClick={menuLGPD} className={classes.root}>
      <Typography
        className={cn(classes.label, {
          [classes.bad]: nivelLGPD === ENUM_NIVEL_LGPD.Nenhum,
          [classes.maybe]: nivelLGPD === ENUM_NIVEL_LGPD.Algum,
          [classes.good]: nivelLGPD === ENUM_NIVEL_LGPD.Todos,
        })}
      >
        {intl.formatMessage({ defaultMessage: "LGPD" })}
      </Typography>
      {nivelLGPD === ENUM_NIVEL_LGPD.Nenhum && <GppBadOutlined />}
      {nivelLGPD === ENUM_NIVEL_LGPD.Algum && <GppMaybeOutlined />}
      {nivelLGPD === ENUM_NIVEL_LGPD.Todos && <GppGoodOutlined />}
      <Menu
        id="menu-lgpd"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        open={!!anchorEl}
      >
        <MenuItem className={classes.itemMenu}>
          <Typography className={classes.titulo}>
            {intl.formatMessage({ defaultMessage: "Base legal para envio de comunicação" })}
          </Typography>
          <Typography className={classes.item}>{retornaLabel(envioComunicacao)}</Typography>
        </MenuItem>
        <MenuItem className={classes.itemMenu}>
          <Typography className={classes.titulo}>
            {intl.formatMessage({ defaultMessage: "Base legal para coleta de dados de voz" })}
          </Typography>
          <Typography className={classes.item}>{retornaLabel(dadosVoz)}</Typography>
        </MenuItem>
        <MenuItem className={classes.itemMenu}>
          <Typography className={classes.titulo}>
            {intl.formatMessage({ defaultMessage: "Base legal para coleta dos dados" })}
          </Typography>
          <Typography className={classes.item}>{retornaLabel(coletaDados)}</Typography>
        </MenuItem>
      </Menu>
    </Flex>
  );
}

MenuLGPD.propTypes = {
  coletaDados: PropTypes.number,
  envioComunicacao: PropTypes.number,
  dadosVoz: PropTypes.number,
  classes: PropTypes.object,
};

export default withStyles({
  root: { cursor: "pointer" },
  titulo: { fontWeight: 500, fontSize: 14 },
  item: { fontWeight: 400, fontSize: 12 },
  label: { fontWeight: 500, fontSize: 13, lineHeight: "22px", letterSpacing: "0.46px" },
  bad: { color: "#F44336" },
  maybe: { color: "#ED6C02" },
  good: { color: "#0F9D58" },
  itemMenu: { display: "block", height: 42 },
})(MenuLGPD);
