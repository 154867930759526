import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import { useIntl } from "react-intl";
import { Tipografia, Flex } from "../../../../_common";

function Dores({ classes, dores = [] }) {
  const intl = useIntl();
  return dores && dores.length ? (
    dores.map((dor, index) => (
      <Flex key={dor.id} className={classes.container} justifyContent="space-between">
        <Tipografia id={`descricao-dor-${index}`}>{`${dor.descricao}${dor.ativo ? "" : " (Inativo)"}`}</Tipografia>
        {dor.principal && <Tipografia>{intl.formatMessage({ defaultMessage: "Principal" })}</Tipografia>}
      </Flex>
    ))
  ) : (
    <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
      {intl.formatMessage({ defaultMessage: "Nenhuma dor cadastrada" })}
    </Tipografia>
  );
}

Dores.propTypes = {
  classes: PropTypes.object,
  dores: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      descricao: PropTypes.string,
      principal: PropTypes.bool,
    })
  ),
};

export default withStyles({
  container: {
    padding: "8px 20px",
    "&:hover": { backgroundColor: "rgb(247,247,247)" },
  },
})(Dores);
