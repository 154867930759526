import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { withStyles, Tooltip } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import Flag from "@material-ui/icons/Flag";
import Close from "@material-ui/icons/Close";

import { Flex } from "../../../../../../_common";

const STATUS_DEBOUNCE = {
  ALTA: 2,
  MEDIA: 1,
  BAIXA: 0,
};

StatusEmail.propTypes = {
  probabilidadeEntrega: PropTypes.number,
  classes: PropTypes.object,
};

function StatusEmail({ probabilidadeEntrega, classes }) {
  const intl = useIntl();
  const [status, setStatus] = useState();

  useEffect(() => {
    switch (probabilidadeEntrega) {
      case 5:
        setStatus(STATUS_DEBOUNCE.ALTA);
        break;
      case 4:
      case 8:
        setStatus(STATUS_DEBOUNCE.MEDIA);
        break;
      default:
        setStatus(STATUS_DEBOUNCE.BAIXA);
        break;
    }
  }, []);

  return (
    <>
      {status === STATUS_DEBOUNCE.ALTA && (
        <Flex className={classes.success}>
          <Tooltip title={intl.formatMessage({ defaultMessage: "Probabilidade de entrega: Alta" })}>
            <Check fontSize="small" className={classes.icon} />
          </Tooltip>
        </Flex>
      )}
      {status === STATUS_DEBOUNCE.MEDIA && (
        <Flex className={classes.alert}>
          <Tooltip title={intl.formatMessage({ defaultMessage: "Probabilidade de entrega: Média" })}>
            <Flag fontSize="small" className={classes.icon} />
          </Tooltip>
        </Flex>
      )}
      {status === STATUS_DEBOUNCE.BAIXA && (
        <Flex className={classes.error}>
          <Tooltip title={intl.formatMessage({ defaultMessage: "Probabilidade de entrega: Baixa ou Nula" })}>
            <Close fontSize="small" className={classes.icon} />
          </Tooltip>
        </Flex>
      )}
    </>
  );
}

export default withStyles({
  icon: { height: 18, width: 18, cursor: "default " },
  success: { borderRadius: "50%", border: "2px #1ba160 solid", color: "#1ba160" },
  alert: { borderRadius: "50%", border: "2px #f5845e solid", color: "#f5845e" },
  error: { borderRadius: "50%", border: "2px #f34336 solid", color: "#f34336" },
  root: {
    padding: "8px 20px",
    "&:hover": {
      backgroundColor: "rgb(247,247,247)",
    },
    "&:hover $hide": {
      visibility: "visible",
    },
  },
  actions: {
    "& > *": {
      marginRight: 6,
      fontSize: 18,
      cursor: "pointer",
    },
  },
  hide: {
    visibility: "hidden",
  },
})(StatusEmail);
