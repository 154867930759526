import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MenuItem, withStyles } from "@material-ui/core";
import { get } from "dot-prop-immutable";

import { useIntl } from "react-intl";
import Listas from "./ListasContext";
import { SelectScrollableMUI } from "../../../../_common";

function SelectListasContext({ field, form, lista, filter = Boolean, allowEmpty, classes, ...props }) {
  const { [lista]: itens } = useContext(Listas);
  const [options, setOptions] = React.useState([]);
  const firstRender = React.useRef(false);
  const intl = useIntl();

  React.useEffect(
    () => {
      setOptions(itens.filter(filter));
    },
    [itens, filter]
  );

  React.useEffect(
    () => {
      if (!firstRender.current) firstRender.current = true;
      else if (field.value && field.value.id && !options.some(({ id }) => field.value.id === id)) {
        form.setFieldValue(field.name);
      }
    },
    [options]
  );

  return (
    <SelectScrollableMUI
      disabled={(!options && !options.length) || form.isSubmitting}
      inputProps={{ name: field.name, id: field.name }}
      value={get(form.values, field.name)}
      renderValue={value => value.descricao || value.nome}
      onChange={e => {
        const selected = options.find(item => item.id === e.target.value);
        if (!selected) form.setFieldValue(field.name, { id: "" });
        else form.setFieldValue(field.name, { id: selected.id, descricao: selected.descricao });
      }}
      {...props}
    >
      {allowEmpty && (
        <MenuItem value="">
          <em>{intl.formatMessage({ defaultMessage: "Nenhuma" })}</em>
        </MenuItem>
      )}
      {options.filter(filter).map(item => (
        <MenuItem key={item.id} value={item.id}>
          {item.descricao || item.nome}
        </MenuItem>
      ))}
    </SelectScrollableMUI>
  );
}

SelectListasContext.propTypes = {
  lista: PropTypes.string.isRequired,
  filter: PropTypes.func,
  field: PropTypes.object,
  form: PropTypes.object,
  classes: PropTypes.object,
  allowEmpty: PropTypes.bool,
};

export default withStyles({ root: { width: "100%" } })(SelectListasContext);
