import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwapHorizontalCircle from "@material-ui/icons/SwapHorizontalCircle";
import { Dialog } from "@material-ui/core";
import { useIntl } from "react-intl";
import axios from "axios";

import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";
import { useUsuario, useSelectPermission } from "../../../../hooks";
import { ACCESS_LEVEL, SITUACAO_LEAD } from "../../../../_common/constantes";
import { AjaxBlackout, createSnackbar } from "../../../../_common";
import ModalTransferirVendedor from "./ModalTransferirVendedor";

TransferirVendedor.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      grupos: PropTypes.arrayOf(PropTypes.number),
      vendedorId: PropTypes.number,
      etapa: PropTypes.shape({ id: PropTypes.number, gateAnterior: PropTypes.number }),
      reuniao: PropTypes.object,
      dataDescarte: PropTypes.string,
      situacao: PropTypes.number,
      preVendedorId: PropTypes.number,
      usuarioOriginalId: PropTypes.number,
    })
  ),
};

function TransferirVendedor({ id, callback = () => null, leads = [], ...props }) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [permiteVisualizar, setPermiteVisualizar] = useState(false);
  const toggle = () => setOpen(o => !o);
  const { grupos, id: usuarioId, isPreVendedor, isGerente, isVendedor } = useUsuario();
  const transferirVendedorLeadPermission = useSelectPermission(permissions.TRANSFERIR_VENDEDOR);
  const leadIndividual = leads.length > 0 ? leads[0] : null;

  useEffect(
    () => {
      if (transferirVendedorLeadPermission) {
        if (isGerente) {
          setPermiteVisualizar(
            transferirVendedorLeadPermission.nivelAcesso !== ACCESS_LEVEL.NONE &&
              transferirVendedorLeadPermission.nivelAcesso !== ACCESS_LEVEL.INDIVIDUAL
          );
        } else {
          setPermiteVisualizar(transferirVendedorLeadPermission.nivelAcesso !== ACCESS_LEVEL.NONE);

          if (transferirVendedorLeadPermission.nivelAcesso === ACCESS_LEVEL.INDIVIDUAL) {
            if (isPreVendedor)
              setPermiteVisualizar(
                leadIndividual?.preVendedorId === usuarioId ||
                  leadIndividual?.vendedorId === usuarioId ||
                  leadIndividual?.usuarioOriginalId === usuarioId
              );
            else if (isVendedor) setPermiteVisualizar(leadIndividual?.vendedorId === usuarioId);
            if (isPreVendedor)
              setPermiteVisualizar(
                leadIndividual?.preVendedorId === usuarioId || leadIndividual?.vendedorId === usuarioId
              );
            else if (isVendedor) setPermiteVisualizar(leadIndividual?.vendedorId === usuarioId);
            else setPermiteVisualizar(true);
          }

          if (transferirVendedorLeadPermission.nivelAcesso === ACCESS_LEVEL.GROUP) {
            if (!isGerente) {
              if (isPreVendedor && leadIndividual?.preVendedorId === usuarioId) setPermiteVisualizar(true);
              else if (isVendedor && leadIndividual?.vendedorId === usuarioId) setPermiteVisualizar(true);
              else {
                setPermiteVisualizar(false);
                const urlBase = `/api/pipeline/usuario1/VendedorNoGrupo?usuarioId=${
                  isPreVendedor ? usuarioId : leadIndividual?.preVendedorId
                }`;
                const gruposUsuarioLogado = transferirVendedorLeadPermission.grupos.filter(l => grupos.includes(l));
                if (gruposUsuarioLogado.length > 0) {
                  const urlPreVendedores = `${urlBase}&${gruposUsuarioLogado.map(t => `grupoIds[]=${t}`).join("&")}`;
                  AjaxBlackout.Show();
                  axios.get(urlPreVendedores).then(resp => {
                    setPermiteVisualizar(resp.data);
                    AjaxBlackout.Hide();
                  });
                }
              }
            } else setPermiteVisualizar(true);
          }
        }
      }
    },
    [transferirVendedorLeadPermission]
  );

  if (leadIndividual?.situacao === SITUACAO_LEAD.DESCARTADO || !permiteVisualizar) return null;

  const finalizarTransferencia = success => {
    if (success !== undefined) {
      if (success) {
        toggle();
        createSnackbar(intl.formatMessage({ defaultMessage: "Operação realizada com sucesso" }));
      } else {
        createSnackbar(intl.formatMessage({ defaultMessage: "Não foi possível realizar a transferência" }));
      }
    }

    callback(true);
  };

  const temVendedorId = leads.some(lead => lead.vendedorId);

  return (
    <>
      <ActionButton
        id="btnTransferirVendedor"
        type="icon"
        icon={<SwapHorizontalCircle />}
        label={intl.formatMessage({ defaultMessage: "Transferir vendedor" })}
        onClick={toggle}
        leads={leads}
        claim={
          temVendedorId &&
          transferirVendedorLeadPermission &&
          transferirVendedorLeadPermission.nivelAcesso !== ACCESS_LEVEL.NONE
        }
        {...props}
      />
      <Dialog maxWidth="sm" fullWidth open={open} onClose={toggle}>
        <ModalTransferirVendedor leads={leads} callback={finalizarTransferencia} onClose={toggle} toggle={toggle} />
      </Dialog>
    </>
  );
}

export default TransferirVendedor;
