import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Dialog, IconButton } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import ModalBloquearContato from "./ModalBloquearContato";

BloquearContato.propTypes = {
  id: PropTypes.number,
};

function BloquearContato({ id, ...props }) {
  const [modalOpen, setModalOpen] = useState(false);
  const intl = useIntl();

  const close = () => {
    setModalOpen(false);
  };

  return (
    <>
      <IconButton title={intl.formatMessage({ defaultMessage: "Bloquear Contato" })} onClick={() => setModalOpen(true)}>
        <BlockIcon />
      </IconButton>

      <Dialog maxWidth="sm" open={modalOpen} onClose={() => close(false)}>
        <ModalBloquearContato id={id} {...props} callback={close} />
      </Dialog>
    </>
  );
}

export default BloquearContato;
