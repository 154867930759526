import React from "react";
import PropTypes from "prop-types";
import { Dialog, withStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { ActionButton } from "../../../../../components";
import ModalBuscarContatos from "./ModalBuscarContatos";
import { Flex } from "../../../../../_common";
import PERMISSIONS from "../../../../../_common/permissions";
import { useUsuario } from "../../../../../hooks";

BuscarContatos.propTypes = {
  lead: PropTypes.object,
  callback: PropTypes.func,
};

function BuscarContatos({ lead, callback, classes }) {
  const [open, setOpen] = React.useState(false);
  const intl = useIntl();
  const toggle = () => setOpen(!open);
  const { featureToggles } = useUsuario();
  if (!lead) return null;

  function onClose(status) {
    if (status) callback();
    toggle();
  }

  return (
    <Flex className={featureToggles.rolloutBuscadorValidador ? classes.newLayout : ""}>
      <ActionButton
        id="btn-buscar-contatos"
        type={featureToggles.rolloutBuscadorValidador ? "button" : "text"}
        color="primary"
        label={
          featureToggles.rolloutBuscadorValidador
            ? intl.formatMessage({ defaultMessage: "Buscar Contatos" })
            : intl.formatMessage({ defaultMessage: "Encontrar Contatos" })
        }
        onClick={toggle}
        claim={PERMISSIONS.ENCONTRARCONTATOS}
        icon={featureToggles.rolloutBuscadorValidador ? <PersonAddIcon style={{ marginRight: 4 }} /> : null}
        className={featureToggles.rolloutBuscadorValidador ? classes.noPadding : ""}
      />
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <ModalBuscarContatos open={open} onClose={onClose} empresa={lead.nome} leadId={lead.id} />
      </Dialog>
    </Flex>
  );
}

export default withStyles(() => ({
  newLayout: { padding: "0px 20px" },
  noPadding: { padding: 0 },
}))(BuscarContatos);
