import React from "react";
import { useApi, useGet } from "../../../../hooks";

const fallbackData = [];
export default function useCamposPersonalizados(entityId, publico = false, isOrganizacao = false) {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  let urlValores = publico
    ? `/api/pipeline/leadpublico/CamposPersonalizados?id=${entityId}`
    : `/api/pipeline/lead/CamposPersonalizados?id=${entityId}&isOrganizacao=${isOrganizacao}`;

  if (isOrganizacao) {
    urlValores = publico
      ? `/api/pipeline/OrganizacaoPublico/CamposPersonalizados?id=${entityId}`
      : `/api/pipeline/Organizacao/CamposPersonalizados?id=${entityId}`;
  }

  const [valores, loading, salvar] = useApi(
    urlValores,
    campos => campos.map(campo => ({ id: campo.id, valor: campo.valor, valores: campo.valores, tipo: campo.tipo })),
    { fallbackData }
  );

  const urlListasParaEdicao = publico
    ? `/api/pipeline/CampoPersonalizadoPublico/Listas?Id=${entityId}&isOrganizacao=${isOrganizacao}`
    : `/api/pipeline/CampoPersonalizado/ListasParaEdicao?isOrganizacao=${isOrganizacao}`;

  const [campos, loadingLista] = useGet(urlListasParaEdicao);

  React.useEffect(
    () => {
      const camposData = campos?.length
        ? campos.map(campo => ({ ...campo, ...valores.find(valor => valor.id === campo.id) }))
        : [];
      setData(camposData);
      setIsLoading(loading || loadingLista);
    },
    [loading, loadingLista, valores, campos]
  );

  return [data, isLoading, salvar];
}
