import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Form, Field } from "formik";
import { get, set } from "dot-prop-immutable";
import { withStyles, InputLabel, FormControl } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Moment from "moment";
import useSWR from "swr";
import PlaceIcon from "@material-ui/icons/Place";
import { Flex, Loading, Tipografia } from "../../../../_common";
import { ClearInput } from "../../../../components";
import Telefones from "../Telefones";
import Listas from "./ListasContext";
import SelectListasContext from "./SelectListasContext";
import CampoSemelhantes from "../CampoSemelhantes";
import { CAMPOS_LEADS_SEMELHANTES } from "../../../../_common/constantes";
import CampoCpfCnpj from "../../../../components/CampoCpfCnpj";
import LeadEnderecoForm from "../EnderecoForm";
import { useLeadsSemelhantes } from "../../../../hooks";
import LeadsSemelhantes from "../LeadsSemelhantes";

function FormEmpresa({ initialValues, values, setValues, id, handleBlur, cpfCnpjConfig, classes }) {
  const intl = useIntl();
  const { data: listas, error: listasError } = useSWR("/api/pipeline/lead/listasparaedicao");
  const clear = name => () => setValues(set(values, name, ""));
  const hasValue = name => !!get(values, name) && !!get(values, name).length;
  const isLoadingListas = !listas && !listasError;

  const [cpfCnpjPesquisa, setCpfCnpjPesquisa] = useState("");
  const [leadsSemelhantes, isLoadingSemelhantes] = useLeadsSemelhantes(
    CAMPOS_LEADS_SEMELHANTES.CPF_CNPJ,
    cpfCnpjPesquisa,
    id
  );

  return (
    <Form>
      <Listas.Provider value={listas}>
        <Loading isLoading={isLoadingListas}>
          <CampoSemelhantes
            id={id}
            fullWidth
            campo={CAMPOS_LEADS_SEMELHANTES.LEAD_NOME}
            label={`${intl.formatMessage({ defaultMessage: "Nome do Lead/Oportunidade" })} *`}
            name="nome"
            helperText={intl.formatMessage({ defaultMessage: "Campo obrigatório *" })}
            validate={val =>
              val && val.trim() ? undefined : intl.formatMessage({ defaultMessage: "Campo obrigatório *" })
            }
            inputProps={{
              maxLength: 500,
            }}
          />
          <Telefones name="telefones" leadId={id} />
          <Flex className={classes.input}>
            <CampoSemelhantes
              id={id}
              fullWidth
              campo={CAMPOS_LEADS_SEMELHANTES.LEAD_SITE}
              label={intl.formatMessage({ defaultMessage: "Site" })}
              name="site"
              inputProps={{
                maxLength: 500,
              }}
            />
          </Flex>
          {cpfCnpjConfig?.habilitar && (
            <Flex className={classes.input}>
              <CampoCpfCnpj
                name="cpfCnpj"
                leadId={id}
                config={cpfCnpjConfig}
                fullWidth
                isLoading={isLoadingSemelhantes}
                SemelhantesComponent={<LeadsSemelhantes {...leadsSemelhantes} />}
                showSemelhantes={leadsSemelhantes?.quantidade > 0}
                onBlur={event => {
                  setCpfCnpjPesquisa(values?.cpfCnpj.replace(/\D/g, "").trim());
                  handleBlur(event);
                }}
              />
            </Flex>
          )}
          <ClearInput
            classes={{ root: classes.input }}
            label={intl.formatMessage({ defaultMessage: "Link RD Station" })}
            name="linkRd"
            hasValue={hasValue("linkRd")}
            clearValue={clear("linkRd")}
          />
          <Flex justifyContent="space-between" className={classes.input}>
            <FormControl fullWidth classes={{ root: classes.inputHalf }}>
              <InputLabel htmlFor="origem">{intl.formatMessage({ defaultMessage: "Origem" })}</InputLabel>
              <Field
                name="origem"
                component={SelectListasContext}
                lista="origens"
                filter={({ ativo, id: idx }) => ativo || idx === initialValues.origem.id}
              />
            </FormControl>
            <FormControl fullWidth classes={{ root: classes.inputHalf }}>
              <InputLabel shrink={!!values?.subOrigem?.id} htmlFor="subOrigem">
                {intl.formatMessage({ defaultMessage: "Sub-Origem" })}
              </InputLabel>
              <Field
                name="subOrigem"
                component={SelectListasContext}
                allowEmpty
                lista="subOrigens"
                filter={({ ativo, id: idx, origemId }) => {
                  const isFromOrigem = +origemId === +values.origem?.id;
                  const isValid = ativo || idx === initialValues.subOrigem.id;
                  return isValid && isFromOrigem;
                }}
              />
            </FormControl>
          </Flex>
          <Flex justifyContent="space-between" className={classes.input}>
            <ClearInput
              label={intl.formatMessage({ defaultMessage: "Produto" })}
              name="produto"
              hasValue={hasValue("produto")}
              clearValue={clear("produto")}
              classes={{ root: classes.inputHalf }}
              inputProps={{ maxLength: 255 }}
            />
            <FormControl fullWidth classes={{ root: classes.inputHalf }}>
              <InputLabel htmlFor="mercado">{intl.formatMessage({ defaultMessage: "Mercado" })}</InputLabel>
              <Field
                name="mercado"
                component={SelectListasContext}
                lista="mercados"
                filter={({ ativo, id: idx }) => ativo || idx === initialValues.mercado.id}
              />
            </FormControl>
          </Flex>

          <Flex justifyContent="space-between" className={classes.input}>
            <FormControl fullWidth classes={{ root: classes.inputHalf }}>
              <Field
                id="dtPrevisaoFechamento"
                name="dtPrevisaoFechamento"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classes.fieldData}
                component={TextField}
                label={intl.formatMessage({ defaultMessage: "Previsão de fechamento" })}
                inputProps={{ min: new Moment().format("YYYY-MM-DD") }}
              />
            </FormControl>
          </Flex>

          <Flex style={{ marginTop: 16, marginBottom: 8 }}>
            <PlaceIcon style={{ marginRight: 8, marginLeft: -6 }} />
            <Tipografia cor="darkSecondaryText" tipo="tituloCard">
              {intl.formatMessage({ defaultMessage: "Endereço" })}
            </Tipografia>
          </Flex>
          <LeadEnderecoForm />
        </Loading>
      </Listas.Provider>
    </Form>
  );
}

FormEmpresa.propTypes = {
  initialValues: PropTypes.object,
  values: PropTypes.object,
  setValues: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cpfCnpjConfig: PropTypes.shape({
    habilitar: PropTypes.bool,
    restringirUnico: PropTypes.bool,
    obrigatorio: PropTypes.bool,
  }),
  handleBlur: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles({
  input: {
    width: "calc(100% - 144px)",
  },
  inputHalf: {
    width: "50%",
    "&:first-child": { marginRight: 24 },
  },
  fieldData: {
    "& input": { marginTop: 12 },
  },
})(FormEmpresa);
