import React from "react";
import PropTypes from "prop-types";
import { InputLabel, FormHelperText, FormControl, withStyles } from "@material-ui/core";
import { get } from "dot-prop-immutable";

import CampoPersonalizado from "./CampoPersonalizado";
import { TooltipTruncate } from "../../../../_common";

function Campo({ field, form, classes, required, ...rest }) {
  const error = get(form.errors, field.name);
  const hasError = !!error && form.submitCount > 0;

  return (
    <FormControl required={required}>
      <InputLabel id="InputLabel" className={rest.tipo === 4 ? classes.root : undefined} error={hasError}>
        <TooltipTruncate>{rest.label}</TooltipTruncate>
      </InputLabel>
      <CampoPersonalizado id="CampoPersonalizado" field={field} form={form} printError={hasError} {...rest} />

      {hasError &&
        !!error && (
          <FormHelperText id="FormHelperText" error>
            {error}
          </FormHelperText>
        )}
    </FormControl>
  );
}

Campo.propTypes = {
  classes: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  required: PropTypes.bool,
};

export default withStyles({ root: { marginTop: "-2em" } })(Campo);
