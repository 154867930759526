import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, Typography, Dialog, Tooltip } from "@material-ui/core";
import { useIntl } from "react-intl";
import axios from "axios";
import cn from "classnames";
import EmailIcon from "@material-ui/icons/Email";

import { Flex } from "../../../../../../_common";
import { ActionButton } from "../../../../../../components";
import { useUsuario, useApi } from "../../../../../../hooks";
import StatusEmailNovo from "../../../contatos/components/StatusEmailNovo/StatusEmailNovo";
import ModalBuscadorEmail from "../../../contatos/components/BuscadorEmail/ModalBuscadorEmail";
import socket, { eActions } from "../../../../../../websocket/socket";

const MINERACAO_MIN_QTD_NOMES = 2;
const MINERACAO_MIN_TAMANHO_NOME = 3;

EncontrarEmailsBuscador.propTypes = {
  identificador: PropTypes.string,
  posicao: PropTypes.number,
  contato: PropTypes.object,
  atualizarContatos: PropTypes.func,
  leadId: PropTypes.number,
  callback: PropTypes.func,
  possuiCreditos: PropTypes.bool,
};

function EncontrarEmailsBuscador({
  identificador,
  posicao,
  contato,
  atualizarContatos,
  leadId,
  callback,
  possuiCreditos,
  classes,
}) {
  const intl = useIntl();
  const { featureToggles } = useUsuario();
  const [buscando, setBuscando] = useState(false);
  const [abrirModal, setAbrirModal] = useState(false);
  const [inativarEncontrarEmails, setInativarEncontrarEmails] = useState(false);
  const [mensagemInativarEncontrarEmails, setMensagemInativarEncontrarEmails] = useState("");

  const [company] = useApi(`/api/pipeline/lead/empresa?id=${leadId}`);

  if (!featureToggles.rolloutBuscadorValidador) return null;

  const toggle = () => setAbrirModal(o => !o);

  const callbackModalInformativa = e => {
    toggle();
    if (e) Buscar();
  };

  const Buscar = () => {
    setBuscando(true);
    axios
      .post("/api/pipeline/Contatos/EncontrarEmailsBuscador", {
        ...contato,
        identificador,
        posicao,
        site: company.site,
      })
      .then(() => {
        callback(true, posicao);
      })
      .catch(() => setBuscando(false));
  };

  const retornoSignalR = data => {
    if (data.sucesso) {
      atualizarContatos(data.posicao, data.emails);
    } else {
      setInativarEncontrarEmails(true);
      setMensagemInativarEncontrarEmails(
        intl.formatMessage({
          defaultMessage: "Nenhum e-mail foi encontrado",
        })
      );
    }
    setBuscando(false);
    callback(false, posicao);
  };

  useEffect(() => {
    socket.on(`${eActions.ENCONTRAR_EMAILS_BUSCADOR_CONTATOS}_${identificador}_${posicao}`, retornoSignalR);
    return () => {
      socket.off(`${eActions.ENCONTRAR_EMAILS_BUSCADOR_CONTATOS}_${identificador}_${posicao}`, retornoSignalR);
    };
  }, []);

  useEffect(
    () => {
      const contatoNome = contato.Nome || "";
      const nomes = contatoNome.trim().split(" ");
      const temPoucosNomes = nomes.length < MINERACAO_MIN_QTD_NOMES;
      const naoTemSite = !company?.site;
      const [nome, ...sobrenomes] = nomes;
      const nomesMuitoCurtos =
        nome.length < MINERACAO_MIN_TAMANHO_NOME || sobrenomes.join(" ").length < MINERACAO_MIN_TAMANHO_NOME;

      if (!possuiCreditos || temPoucosNomes || naoTemSite || nomesMuitoCurtos) {
        setInativarEncontrarEmails(true);
        setMensagemInativarEncontrarEmails(
          intl.formatMessage({
            defaultMessage:
              "Não é possível buscar e-mail se a empresa não tiver site ou o lead não tiver nome e sobrenome",
          })
        );
      } else {
        setInativarEncontrarEmails(false);
        setMensagemInativarEncontrarEmails("");
      }
    },
    [company]
  );

  return (
    <>
      <Flex alignItems="center">
        {buscando && (
          <Typography color="textSecondary">{intl.formatMessage({ defaultMessage: "BUSCANDO..." })}</Typography>
        )}
        {!buscando &&
          (!contato?.emails || contato?.emails.length === 0) && (
            <Tooltip title={mensagemInativarEncontrarEmails}>
              <Flex alignItems="center">
                <ActionButton
                  id="btn-encontrar-emails"
                  type="button"
                  className={cn(classes.primario, classes.botaoEncontrarEmails)}
                  label={intl.formatMessage({ defaultMessage: "Encontrar E-mails" })}
                  onClick={toggle}
                  icon={<EmailIcon style={{ marginRight: 4 }} />}
                  style={{ paddingLeft: 0 }}
                  disabled={inativarEncontrarEmails}
                />
              </Flex>
            </Tooltip>
          )}
        {contato?.emails &&
          contato?.emails.length > 0 && (
            <Flex flexDirection="column" fullWidth>
              {contato?.emails.map((item, index) => (
                <Flex key={index}>
                  <Flex className={classes.espacoStatusEmail}>
                    <StatusEmailNovo probabilidadeEntrega={item.probabilidadeEntrega} />
                  </Flex>
                  <Typography className={cn(classes.emailBase, classes.primario)}>{item.endereco}</Typography>
                </Flex>
              ))}
            </Flex>
          )}
      </Flex>
      <Dialog maxWidth="sm" fullWidth open={abrirModal}>
        <ModalBuscadorEmail
          onClose={callbackModalInformativa}
          contato={{ nome: contato.Nome }}
          site={company?.site}
          modalInformativa
        />
      </Dialog>
    </>
  );
}

export default withStyles(() => ({
  primario: { color: "#457AB7" },
  botaoEncontrarEmails: { margin: "8px 0" },
  espacoStatusEmail: { marginRight: 12 },
  emailBase: {
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 14,
    marginBottom: 8,
  },
}))(EncontrarEmailsBuscador);
