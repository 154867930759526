import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import useSWR from "swr";
import { FieldArray, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Typography, FormControlLabel, Radio, Button, withStyles, IconButton } from "@material-ui/core";
import { useParams } from "react-router";
import Delete from "@material-ui/icons/Delete";
import { Flex } from "../../../../_common";
import SelectBasesLegais from "../../../../components/SelectBasesLegais";
import Telefones from "../Telefones";
import Emails from "../Emails";
import Mensageiros, { GET_DEFAULT_MENSAGEIRO } from "../Mensageiros";
import BloquearContato from "./components/BloquearContato";
import { BASE_LEGAL } from "../../../../_common/constantes";
import LeadContatosSelectCargo from "./SelectCargo/SelectCargo";
import { FormikErrorScroller } from "../../../../components";
import { GET_DEFAULT_TELEFONE } from "../Telefones/Telefones";
import { GET_DEFAULT_EMAIL } from "../Emails/Emails";

export const emptyContact = principal => ({
  nome: "",
  cargoObj: { id: null, descricao: "" },
  telefones: [GET_DEFAULT_TELEFONE()],
  mensageiros: [GET_DEFAULT_MENSAGEIRO()],
  emails: [GET_DEFAULT_EMAIL()],
  principal,
  coletaDados: BASE_LEGAL.NENHUMA,
  envioComunicacao: BASE_LEGAL.NENHUMA,
  dadosVoz: BASE_LEGAL.NENHUMA,
});

function FormContatos({ values, lead, exigirNomeTelefone = false, atualizarListaContatos, cadastro = false, classes }) {
  const intl = useIntl();
  const { leadId } = useParams();
  const { data: protecaoDados } = useSWR("/api/pipeline/protecaoDados/buscar");

  const { data: basesLegais } = useSWR(leadId ? `/api/pipeline/lead/baseLegal?leadId=${leadId}` : null);

  const canRemove = !(exigirNomeTelefone && values.contatos.length === 1);

  const mostrarBloquearContato = contato =>
    !(!contato.emails[0]?.id && !contato.telefones[0]?.id && !contato.mensageiros[0]?.id);

  return (
    <>
      <FormikErrorScroller />
      <FieldArray
        name="contatos"
        render={({ remove, push, form: { setValues, isSubmitting } }) => (
          <>
            {values.contatos.map((contato, index) => (
              <div
                className={classes.contatoWrapper}
                data-scroll-anchor={`contatos.${index}`}
                key={contato.id || index}
              >
                <Flex justifyContent="space-between" alignItems="center" className={classes.name}>
                  <Typography variant="subtitle1">
                    {intl.formatMessage({ defaultMessage: "Contato" })} {index + 1}
                  </Typography>
                  <Flex alignItems="center">
                    {!cadastro && mostrarBloquearContato(contato) && <BloquearContato id={contato.id} />}

                    {canRemove && (
                      <IconButton
                        disabled={isSubmitting}
                        title={intl.formatMessage({ defaultMessage: "Remover Contato" })}
                        onClick={() => {
                          const contatos = values.contatos
                            .filter((item, fIndex) => fIndex !== index)
                            .map(({ principal, ...rest }, ind) => ({
                              ...rest,
                              principal: !ind,
                            }));

                          if (!cadastro) {
                            if (contatos.length > 0) {
                              atualizarListaContatos(contatos);
                            } else remove(index);
                          } else {
                            setValues({
                              ...values,
                              contatos,
                            });
                          }
                        }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Flex>
                </Flex>

                <div className={classes.formWrapper}>
                  <Flex className={classes.row}>
                    <Field
                      fullWidth
                      name={`contatos.${index}.nome`}
                      label={intl.formatMessage({ defaultMessage: "Nome do Contato" })}
                      component={TextField}
                      validate={e =>
                        e && e.trim() ? undefined : intl.formatMessage({ defaultMessage: "Campo obrigatório *" })
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                  </Flex>
                  <Flex className={classes.row}>
                    <Field
                      fullWidth
                      name={`contatos.${index}.cargoObj`}
                      component={LeadContatosSelectCargo}
                      inputProps={{ maxLength: 50 }}
                      disabled={isSubmitting}
                      contato={contato}
                      freeSolo
                    />
                  </Flex>
                  <Telefones
                    name={`contatos.${index}.telefones`}
                    requiredOne={contato.principal && exigirNomeTelefone}
                    lead={lead}
                  />
                  <Emails name={`contatos.${index}.emails`} lead={lead} />
                  <Mensageiros name={`contatos.${index}.mensageiros`} lead={lead} />

                  {protecaoDados && (
                    <>
                      <SelectBasesLegais
                        values={values.contatos[index]}
                        fieldNames={{
                          coletaDados: `contatos.${index}.coletaDados`,
                          envioComunicacao: `contatos.${index}.envioComunicacao`,
                          dadosVoz: `contatos.${index}.dadosVoz`,
                        }}
                      />
                    </>
                  )}
                  <Flex className={classes.row}>
                    <FormControlLabel
                      control={
                        <Radio
                          name="contato-principal"
                          color="primary"
                          checked={contato.principal}
                          disabled={isSubmitting}
                          onChange={() =>
                            setValues({
                              ...values,
                              contatos: values.contatos.map(({ principal, ...rest }, ind) => ({
                                ...rest,
                                principal: index === ind,
                              })),
                            })
                          }
                        />
                      }
                      label={intl.formatMessage({ defaultMessage: "Este é o contato principal" })}
                    />
                  </Flex>
                </div>
              </div>
            ))}
            <Button
              disabled={isSubmitting}
              className={classes.addButton}
              onClick={() =>
                push({
                  ...emptyContact(!values.contatos.length),
                  coletaDados: basesLegais?.coletaDados ?? BASE_LEGAL.NENHUMA,
                  dadosVoz: basesLegais?.dadosVoz ?? BASE_LEGAL.NENHUMA,
                  envioComunicacao: basesLegais?.envioComunicacao ?? BASE_LEGAL.NENHUMA,
                })
              }
            >
              {intl.formatMessage({ defaultMessage: "Adicionar novo contato" })}
            </Button>
          </>
        )}
      />
    </>
  );
}

FormContatos.propTypes = {
  values: PropTypes.object,
  /** Determina a obrigatoriedade do contato principal ter pelo menos um número de telefone cadastrado */
  exigirNomeTelefone: PropTypes.bool,
  cadastro: PropTypes.bool,
  lead: PropTypes.object,
  atualizarListaContatos: PropTypes.func,
};

export default withStyles({
  row: {
    width: "calc(100% - 144px)",
  },
  italic: {
    fontStyle: "italic",
  },
  normal: {
    fontStyle: "normal",
  },
  contatoWrapper: {
    display: "flex",
    flexDirection: "column",
    "&:not(:first-child)": {
      paddingTop: 24,
    },
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 24,
  },
  addButton: {
    marginTop: 12,
  },
  name: {
    height: 32,
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
})(FormContatos);
