import React from "react";
import { useIntl } from "react-intl";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import useSWR, { mutate as mutateBaseLegal } from "swr";
import Outline from "../Outline";
import FormEmpresa from "./FormEmpresa";
import {
  createAsyncDialog,
  Loading,
  createSnackbar,
  createSnackbarAPIException,
  AjaxBlackout,
} from "../../../../_common";
import { useApi, usePermission } from "../../../../hooks";
import Empresa from "./Empresa";
import permissions from "../../../../_common/permissions";
import { atualizarEmpresa, buscarListaContatos } from "../../../../store/reducers/detalhesLead/detalhesLeadActions";
import { GET_DEFAULT_TELEFONE } from "../Telefones/Telefones";

const INITIAL_VALUES = {
  origem: { id: "" },
  subOrigem: { id: "" },
  site: "",
  linkRd: "",
  produto: "",
  endereco: { bairro: "", logradouro: "" },
  cpfCnpj: "",
  dtPrevisaoFechamento: "",
  telefones: [GET_DEFAULT_TELEFONE()],
  atualizarProfiler: false,
};

EmpresaOutline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lead: PropTypes.object,
  classes: PropTypes.object,
  atualizar: PropTypes.func,
  buscarLista: PropTypes.func,
  isEditing: PropTypes.func,
  protecaoDados: PropTypes.bool,
  handlePrevisaoChange: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  atualizar: empresa => dispatch(atualizarEmpresa(empresa)),
  buscarLista: id => dispatch(buscarListaContatos(id)),
});
const mapStateToProps = state => ({
  protecaoDados: state.usuario.protecaoDados,
});

function EmpresaOutline({
  id,
  lead,
  atualizar,
  buscarLista,
  protecaoDados,
  isEditing,
  handlePrevisaoChange,
  ...props
}) {
  const intl = useIntl();
  const [company, loadingEmpresa, salvar, mutate] = useApi(`/api/pipeline/lead/empresa?id=${id}`);
  const [updatedCompany, setUpdatedCompany] = React.useState(company);
  const [initialValues, setInitialValues] = React.useState(INITIAL_VALUES);
  const { data: config } = useSWR(`/api/pipeline/lead/ConfiguracoesCadastro`);
  const isLoading = loadingEmpresa;
  if (!isLoading) {
    atualizar(company);
  }

  React.useEffect(
    () => {
      if (company) {
        const updatedCompanyData = {
          ...company,
          dtPrevisaoFechamento: lead?.previsaoFechamento || company.dtPrevisaoFechamento,
        };
        setUpdatedCompany(updatedCompanyData);

        setInitialValues(prevValues => ({
          ...prevValues,
          ...updatedCompanyData,
          dtPrevisaoFechamento:
            (lead?.previsaoFechamento && new Date(lead?.previsaoFechamento).toISOString().split("T")[0]) ||
            (company.dtPrevisaoFechamento && new Date(company.dtPrevisaoFechamento).toISOString().split("T")[0]) ||
            null,
          endereco: { ...INITIAL_VALUES.endereco, ...company.endereco },
          telefones: !company?.telefones.length ? INITIAL_VALUES.telefones : company.telefones,
          atualizarProfiler: false,
        }));
      }
    },
    [company, lead]
  );

  const canEdit = usePermission(permissions.ALTERAR_EMPRESA, lead);

  function retirarMascara(numero) {
    return numero
      .toString()
      .replace(/[( )-]+/g, "")
      .trim();
  }

  const onSubmit = async form => {
    const origemAlterada =
      company.origem.id !== form.origem.id ||
      (company.subOrigem && form.subOrigem && company.subOrigem.id !== form.subOrigem.id);

    if (protecaoDados && origemAlterada) {
      const { data } = await axios.post(`/api/pipeline/lead/baseLegal`, {
        origemId: form.origem.id,
        subOrigemId: form.subOrigem ? form.subOrigem.id : null,
      });

      if (data) {
        try {
          await createAsyncDialog({
            title: intl.formatMessage({ defaultMessage: "Atualizar bases legais" }),
            text: intl.formatMessage({
              defaultMessage:
                "A nova origem/sub-origem vinculada possui bases legais configuradas. Deseja atualizar as bases legais existentes nos contatos desse lead de acordo com as bases legais configuradas para essa origem/sub-origem selecionada?",
            }),
            acceptLabel: intl.formatMessage({ defaultMessage: "Atualizar" }),
            dialogProps: { maxWidth: "sm" },
          });
          AjaxBlackout.Show();
          await axios.post(`/api/pipeline/lead/contatosBaseLegal?id=${id}`, data);
          mutateBaseLegal(`/api/pipeline/lead/baseLegal?leadId=${id}`, data, false);
          buscarLista(id);
          createSnackbar(intl.formatMessage({ defaultMessage: "Bases legais atualizadas com sucesso" }));
        } catch (e) {
          if (e) {
            createSnackbarAPIException(e);
          }
        } finally {
          AjaxBlackout.Hide();
        }
      }
    }

    if (company.nome !== form.nome) {
      try {
        await createAsyncDialog({
          title: intl.formatMessage({
            defaultMessage: "Consulta ao Mentor IA",
          }),
          text: intl.formatMessage({
            defaultMessage: "O nome do lead foi alterado, deseja atualizar o conteúdo do Mentor IA?",
          }),
          acceptLabel: intl.formatMessage({ defaultMessage: "Confirmar" }),
          cancelLabel: intl.formatMessage({ defaultMessage: "Cancelar" }),
          fullWidth: true,
          tamanhoMaximo: "sm",
        });

        form.atualizarProfiler = true;
      } catch (e) {
        if (e) {
          createSnackbarAPIException(e);
        }
      } finally {
        AjaxBlackout.Hide();
      }
    }
    return handleSubmit({ ...form });
  };

  const handleSubmit = async form => {
    try {
      const response = await salvar({
        ...form,
        cpfCnpj: form.cpfCnpj.replace(/\D/g, ""),
        telefones: form.telefones
          .filter(telefone => telefone.numero)
          .map(tel => ({ ...tel, numero: retirarMascara(tel.numero) })),
        emails: form.emails.filter(email => email.endereco),
      });
      if (typeof handlePrevisaoChange === "function") handlePrevisaoChange(form.dtPrevisaoFechamento);
      createSnackbar(intl.formatMessage({ defaultMessage: "Alterações salvas com sucesso" }));
      document.dispatchEvent(new Event("detalhe-lead/painel/reload"));
      document.dispatchEvent(
        new CustomEvent("detalhe-lead/timeline/reloadLogFilterTriggers", { detail: { flag: true } })
      );
      if (form.atualizarProfiler) {
        document.dispatchEvent(new Event("magicProfiler/reload"));
      }
      document.dispatchEvent(new CustomEvent("painel-empresa/salvar", { detail: form }));
      mutate();
      setTimeout(() => buscarLista(id), 200);
      return response;
    } catch (e) {
      createSnackbarAPIException(e);
      return false;
    }
  };

  return (
    <Outline
      {...props}
      id={id}
      title={intl.formatMessage({ defaultMessage: "Lead/Oportunidade" })}
      form={canEdit ? FormEmpresa : null}
      initialValues={initialValues}
      isEditing={isEditing}
      onSubmit={onSubmit}
      cpfCnpjConfig={config?.configuracoesCpfCnpj}
      editButtonId="btn-edit-empresa"
    >
      <Loading isLoading={isLoading}>
        <Empresa {...{ ...updatedCompany, id }} habilitaCpfCnpj={config?.configuracoesCpfCnpj?.habilitar} />
      </Loading>
    </Outline>
  );
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(EmpresaOutline);
