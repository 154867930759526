import React from "react";
import { useIntl } from "react-intl";
import { Formik, Form, Field } from "formik";
import { Button, DialogContent, DialogActions } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { createDialogConfirm } from "../../../../_common";
import { maybeCallback } from "../../../../_common/utils/fp";

export default function useConfirmarTransferencia({ ids, onClose = x => x, transferirVendedorLote = x => x }) {
  const intl = useIntl();

  const confirmar = close => {
    maybeCallback(close)();
    transferirVendedorLote();
  };

  function validarTransferencia(value) {
    if (value !== ids.length.toString()) {
      return intl.formatMessage({ defaultMessage: "Informe o valor certo de leads a serem transferidos" });
    }
    return "";
  }

  function confirmarTransferencia() {
    createDialogConfirm({
      title: intl.formatMessage(
        { defaultMessage: "Você irá transferir de vendedor {quantidade} lead(s), deseja continuar?" },
        { quantidade: ids.length }
      ),
      text: intl.formatMessage({
        defaultMessage:
          "Se optar por continuar a ação, o agendamento e métricas serão transferidos, quando houver. \nPara continuar, digite o número de leads que serão transferidos:",
      }),
      tamanhoMaximo: "sm",
      actions: close => (
        <Formik initialValues={{ quantidadeLeads: "" }} onSubmit={() => confirmar(close)} onReset={onClose}>
          {() => (
            <Form>
              <DialogContent style={{ display: "flex", flexDirection: "column" }}>
                <Field
                  component={TextField}
                  label={intl.formatMessage({
                    defaultMessage: "Confirme a quantidade de leads a serem transferidos:",
                  })}
                  name="quantidadeVendedores"
                  required
                  validate={validarTransferencia}
                  InputLabelProps={{ style: { fontSize: 13 } }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={close}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
                <Button color="primary" type="submit">
                  {intl.formatMessage({ defaultMessage: "Confirmar transferência de vendedores" })}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      ),
    });
  }

  return {
    confirmarTransferencia,
  };
}
