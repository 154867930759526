import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import axios from "axios";
import useSWR from "swr";
import { useFormikContext } from "formik";
import AutoComplete from "../../../../../_common/AutoComplete/AutoComplete";
import AsyncSelect from "../../../../../components/AsyncSelect";
import { useUsuario } from "../../../../../hooks";

const fetcher = url => axios.get(url).then(res => res.data);

function LeadContatosSelectCargo(props) {
  const intl = useIntl();
  const { data: listaCargos, mutate: recarregarLista } = useSWR(`/Api/Pipeline/Cargo/ListarDescricao`, fetcher);
  const { featureToggles } = useUsuario();
  const { setFieldValue } = useFormikContext();
  const { contato, field } = props;
  const [valorCargo, setValor] = useState(contato?.cargoObj?.id || "");

  const validarCargo = valor => {
    // eslint-disable-next-line no-restricted-globals
    const somenteNumeros = !isNaN(parseFloat(valor)) && isFinite(valor);
    const possuiArroba = valor?.includes("@");
    if (somenteNumeros || possuiArroba) return intl.formatMessage({ defaultMessage: "Formato cargo inválido " });

    return "";
  };

  const alterarValor = id => {
    const cargo = listaCargos.find(x => x.id === id);
    if (id !== undefined) {
      setValor(id);
      setFieldValue(field.name, cargo, true);
    }
  };

  return (
    <>
      {!featureToggles.habilitarSelectSimplesCargo && (
        <AutoComplete
          label={intl.formatMessage({ defaultMessage: "Cargo" })}
          dataSourceConfig={{ id: "id", text: "descricao" }}
          api={debouncedSearch => `/Api/Pipeline/Cargo/ListarDescricao?descricao=${debouncedSearch}&somenteAtivos=true`}
          apresentarError
          validarValor={validarCargo}
          validarNovo
          {...props}
        />
      )}

      {featureToggles.habilitarSelectSimplesCargo && (
        <AsyncSelect
          getInputLabel={item => item.descricao}
          options={listaCargos}
          label={intl.formatMessage({ defaultMessage: "Cargo" })}
          id="Cargo"
          enableSearch
          urlCriarNovo={`/spotter/configuracoes/padroes`}
          criarNovo
          funcaoRecarregar={recarregarLista}
          onChange={event => alterarValor(event.target.value)}
          value={valorCargo}
          {...props}
        />
      )}
    </>
  );
}

LeadContatosSelectCargo.propTypes = {
  nomeCampo: PropTypes.string,
  field: PropTypes.object,
  contato: PropTypes.object,
};

export default LeadContatosSelectCargo;
