import React from "react";
import PropTypes from "prop-types";
import Assignment from "@material-ui/icons/Assignment";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";

VerAtividades.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nome: PropTypes.string,
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
    nome: PropTypes.string,
  }),
};

export default function VerAtividades({ id, nome, lead = {}, ...props }) {
  const intl = useIntl();
  return (
    <Link to={`/spotter/atividades?leadId=${id || lead.id}&nomeLead=${nome || lead.nome}`}>
      <ActionButton
        claim={permissions.MODULOATIVIDADES}
        id="btn-ver-atividades"
        type="menu"
        tooltip={intl.formatMessage({ defaultMessage: "Ver atividades" })}
        icon={<Assignment />}
        label={intl.formatMessage({ defaultMessage: "Ver atividades do lead" })}
        {...props}
      />
    </Link>
  );
}
