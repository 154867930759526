import React from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import axios from "axios";
import { withStyles } from "@material-ui/core";

import { useIntl } from "react-intl";
import { Flex } from "../../../../_common";
import Campo from "./Campo";
import { TIPO_CAMPO_PERSONALIZADO } from "../../../../_common/constantes";
import { validarHiperLink } from "../../../../_common/utils/string/string";

const CAMPO_OBRIGATORIO = "Campo Obrigatório *";

async function validate(campoField, campo, shouldValidateUnique, isOrganizacao, intl) {
  const { obrigatorio, unico, id, tipo, entityId } = campo;
  let err;
  if (obrigatorio) err = validateRequired(campoField, tipo);
  if (err) return err;
  if (tipo === TIPO_CAMPO_PERSONALIZADO.LINK && !validarHiperLink(campoField.valor)) {
    return intl.formatMessage({ defaultMessage: "O valor neste campo deve ser um link" });
  }
  if (unico && shouldValidateUnique) {
    return validateUnique(campoField, id, entityId, isOrganizacao);
  }
  return undefined;
}

function validateRequired(campoField, tipo) {
  if (!campoField) return CAMPO_OBRIGATORIO;

  switch (tipo) {
    case TIPO_CAMPO_PERSONALIZADO.TEXTO_CURTO:
    case TIPO_CAMPO_PERSONALIZADO.TEXTO_LONGO:
    case TIPO_CAMPO_PERSONALIZADO.LINK:
    case TIPO_CAMPO_PERSONALIZADO.NUMERO:
      if (!campoField.valor) return CAMPO_OBRIGATORIO;
      break;
    case TIPO_CAMPO_PERSONALIZADO.ESCOLHA_UNICA:
    case TIPO_CAMPO_PERSONALIZADO.ESCOLHA_MULTIPLA:
      if (!campoField.valores?.length) return CAMPO_OBRIGATORIO;
      break;
    default:
      return "";
  }
  return "";
}

async function validateUnique(campoField, campoId, entityId, isOrganizacao) {
  const { valor, valores } = campoField;
  let isUnique = false;
  try {
    const { data } = await axios.get(
      `/api/pipeline/CampoPersonalizado/ValidarUnicidade?id=${campoId}&valor=${valor ||
        valores}&entityId=${entityId}&isOrganizacao=${isOrganizacao}`
    );
    isUnique = data;
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }

  const errorMsg = isOrganizacao
    ? "O valor neste campo deve ser único entre as Organizações"
    : "O valor neste campo deve ser único entre os Leads";

  return !isUnique ? errorMsg : undefined;
}

function CamposPersonalizadosField({
  campo,
  shouldValidateUnique = true,
  name,
  classes,
  isOrganizacao = true,
  ...props
}) {
  const { id, opcoes, nome, tipo, obrigatorio, unico, ...restCampo } = campo;
  const intl = useIntl();
  const [field] = useField({
    name,
    validate: campoField => validate(campoField, campo, shouldValidateUnique, isOrganizacao, intl),
  });
  const form = useFormikContext();

  return (
    <Flex flexDirection="column" className={classes.root} key={id}>
      <Campo
        key={id}
        opcoes={opcoes}
        tipo={tipo}
        label={nome}
        component={Campo}
        obrigatorio={obrigatorio}
        unico={unico}
        id={id}
        field={field}
        form={form}
        required={campo.obrigatorio}
        {...restCampo}
        {...props}
      />
    </Flex>
  );
}

CamposPersonalizadosField.propTypes = {
  campo: PropTypes.shape({
    nome: PropTypes.string,
    id: PropTypes.number,
    tipo: PropTypes.number,
    opcoes: PropTypes.array,
    classes: PropTypes.object,
    obrigatorio: PropTypes.bool,
    unico: PropTypes.bool,
    /** Id do Lead ou da Organização */
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  name: PropTypes.string.isRequired,
  shouldValidateUnique: PropTypes.bool,
  isOrganizacao: PropTypes.bool,
};

export default withStyles({
  root: { marginTop: 25 },
  campo: { width: "calc(100% - 144px)" },
})(CamposPersonalizadosField);
