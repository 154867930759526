/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { withStyles, IconButton, Tooltip } from "@material-ui/core";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import moment from "moment";

import { useIntl } from "react-intl";
import { Flex, Tipografia, createSnackbar, createAsyncDialog, AjaxBlackout } from "../../../../_common";

Arquivos.propTypes = {
  classes: PropTypes.object,
  arquivos: PropTypes.array,
  refresh: PropTypes.func,
};

function Arquivos({ classes, arquivos = [], refresh }) {
  const intl = useIntl();

  const excluir = async id => {
    try {
      await createAsyncDialog({
        title: intl.formatMessage({ defaultMessage: "Excluir arquivo" }),
        text: intl.formatMessage({
          defaultMessage: "Essa operação irá excluir este anexo permanentemente. Deseja continuar com essa operação?",
        }),
        acceptLabel: intl.formatMessage({ defaultMessage: "Confirmar" }),
        dialogProps: { maxWidth: "sm" },
      });
      AjaxBlackout.Show();
      await axios.post(`/api/pipeline/lead/RemoverArquivo?anexoId=${id}`);
      refresh();
      createSnackbar(intl.formatMessage({ defaultMessage: "Arquivo excluido com sucesso" }));
    } catch (err) {
      if (err) {
        createSnackbar(intl.formatMessage({ defaultMessage: "Não foi possível excluir este arquivo" }));
      }
    } finally {
      AjaxBlackout.Hide();
    }
  };

  return arquivos.length ? (
    arquivos.map(arquivo => (
      <Flex key={arquivo.id} className={classes.container} justifyContent="space-between">
        <a target="_blank" id={`file-content-${arquivo.id}`} href={arquivo.caminho} download={arquivo.nome} />
        <Flex className={classes.containerItems} alignItems="center" justifyContent="space-between">
          <Tipografia className={classes.nomeArquivo}>{arquivo.nome}</Tipografia>
          <Tipografia className={classes.tamanhoArquivo}>{arquivo.tamanhoMb}</Tipografia>
          <Tipografia>{`${arquivo.usuarioNome} ${arquivo.usuarioSobrenome}`}</Tipografia>
          <Tipografia className={classes.dataArquivo}>
            {intl.formatDate(moment(arquivo.data), {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </Tipografia>
        </Flex>
        <Flex justifyContent="space-between" className={classes.hide}>
          <Tooltip title={intl.formatMessage({ defaultMessage: "Download" })}>
            <IconButton
              className={classes.actionButton}
              aria-label="Download"
              onClick={() => document.getElementById(`file-content-${arquivo.id}`).click()}
            >
              <GetAppRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={intl.formatMessage({ defaultMessage: "Excluir arquivo" })}>
            <IconButton className={classes.actionButton} aria-label="Delete" onClick={() => excluir(arquivo.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Flex>
      </Flex>
    ))
  ) : (
    <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
      {intl.formatMessage({ defaultMessage: "Nenhum arquivo encontrado" })}
    </Tipografia>
  );
}

export default withStyles({
  content: { maxHeight: "220px" },
  container: {
    padding: "8px 4px 8px 20px",
    "&:hover": { backgroundColor: "rgb(247,247,247)" },
    "&:hover $hide": {
      visibility: "visible",
    },
  },
  hide: {
    visibility: "hidden",
  },
  containerItems: {
    width: "90%",
  },
  nomeArquivo: {
    width: "50%",
  },
  tamanhoArquivo: {
    width: "10%",
    textAlign: "end",
  },
  dataArquivo: {
    width: "10%",
  },
  actionButton: {
    padding: "0 0 0 12px",
  },
})(Arquivos);
