import React, { useState } from "react";
import useSWR from "swr";
import { Dialog, withStyles, Tooltip, Button } from "@material-ui/core";
import { YoutubeSearchedFor } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ModalValidacaoEmail from "./ModalValidacaoEmail";

import { useTipoPlanoSpotter } from "../../../../../../hooks";
import MarkEmailRead from "../../../../../../_common/icones/MarkEmailRead";

ValidacaoEmail.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object,
  contato: PropTypes.object,
  botao: PropTypes.bool,
};

function ValidacaoEmail({ value, classes, contato, botao = false }) {
  const { data: creditos } = useSWR("/api/pipeline/FuncionalidadePlano/CreditosBuscador");

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(o => !o);
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    <>
      {isSpotterFull && (
        <>
          {creditos < 1 ? (
            <Tooltip
              title={intl.formatMessage({
                defaultMessage: "Você não possui créditos o suficiente para realizar novas buscas",
              })}
            >
              {!botao ? (
                <YoutubeSearchedFor fontSize="small" className={classes.iconDisabled} />
              ) : (
                <span>
                  <Button variant="contained" disabled size="small" className={classes.btnValidar}>
                    <MarkEmailRead className={classes.btnIcon} height={20} width={20} />
                  </Button>
                </span>
              )}
            </Tooltip>
          ) : (
            <>
              {botao && (
                <Tooltip
                  title={intl.formatMessage({
                    defaultMessage: "Validar",
                  })}
                >
                  <Button
                    onClick={toggle}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.btnValidar}
                  >
                    <MarkEmailRead className={classes.btnIcon} height={18} width={16} />
                  </Button>
                </Tooltip>
              )}
              {!botao && (
                <Tooltip title="Validar email">
                  <YoutubeSearchedFor fontSize="small" color="primary" onClick={toggle} className={classes.icon} />
                </Tooltip>
              )}
            </>
          )}

          <Dialog maxWidth="sm" fullWidth open={open} onClose={toggle}>
            <ModalValidacaoEmail onClose={toggle} value={value} contato={contato} />
          </Dialog>
        </>
      )}
    </>
  );
}

export default withStyles({
  error: { height: 21, width: 21, cursor: "default" },
  success: { color: "green", height: 21, width: 21, cursor: "default " },
  icon: { height: 21, width: 21 },
  iconDisabled: { color: "rgba(0,0,0,0.54)", height: 21, width: 21, cursor: "default" },
  btnValidar: {
    color: "#fff",
    borderRadius: 12,
    fontSize: 24,
    height: 24,
    padding: "4px 8px",
    minWidth: "24px!important",
  },
  btnIcon: { marginRight: 4 },
})(ValidacaoEmail);
