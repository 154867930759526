import React from "react";
import PropTypes from "prop-types";

import { useIntl } from "react-intl";
import Outline from "../Outline";
import { Loading, createSnackbar } from "../../../../_common";
import { useApi, useGet, useQueryParams, usePermission, useEvent } from "../../../../hooks";
import FormDores from "./FormDores";
import ListasContext from "../empresa/ListasContext";
import Dores from "./Dores";
import permissions from "../../../../_common/permissions";
import { appendLabelInativo } from "../../../../_common/utils/string";

function DoresOutline({ id, classes, lead, isEditing, ...props }) {
  const intl = useIntl();
  const { etapaId, id: questionarioId } = useQueryParams();
  const [lista, loadingLista] = useGet(`/api/pipeline/dor/listar?leadId=${id}`, [], null, item =>
    appendLabelInativo(item, intl)
  );
  const [dores, loading, salvar, reload] = useApi(`/api/pipeline/lead/dores?id=${id}`, valores => ({
    etapaId,
    questionarioId,
    dores: valores.map(dor => ({ id: dor.id, principal: dor.principal })),
  }));
  const hasPermission = usePermission(permissions.ALTERAR_DOR_PRODUTO, lead);

  useEvent("lead.dores.reload", reload);

  return (
    <ListasContext.Provider value={{ dores: lista }}>
      <Outline
        {...props}
        title={intl.formatMessage({
          defaultMessage: "Dores e Objeções",
        })}
        form={hasPermission ? FormDores : null}
        initialValues={{ dores }}
        editButtonId="btn-edit-dores-e-objecoes"
        id="card-Dores"
        isEditing={isEditing}
        onSubmit={({ dores: d }) => {
          const temInativo = d.find(dor => !dor.ativo);

          if (temInativo) {
            createSnackbar("Não foi possível salvar alteração. Verifique as Dores inativas e tente novamente.");
            return Promise.resolve(false);
          }

          return salvar(d).then(success => {
            createSnackbar(
              success
                ? intl.formatMessage({
                    defaultMessage: "Alterações salvas com sucesso",
                  })
                : intl.formatMessage({ defaultMessage: "Ocorreu um erro." })
            );
            return success;
          });
        }}
        schema={{}}
        loading={loadingLista}
      >
        {loading && <Loading isLoading />}

        {!loading && <Dores dores={dores} />}
      </Outline>
    </ListasContext.Provider>
  );
}

DoresOutline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.object,
  isEditing: PropTypes.func,
  lead: PropTypes.shape({
    grupos: PropTypes.arrayOf(PropTypes.number),
    preVendedorId: PropTypes.number,
  }),
};

export default DoresOutline;
