import React from "react";
import { withStyles, Typography, CircularProgress, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import { MailOutlined, Close, Check, FileCopyOutlined, FindInPage } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { defineMessage } from "react-intl";
import { useHistory } from "react-router";

import { ESTADO_MINERACAO } from "../../../../../../_common/constantes";
import {
  abrirEscreverEmail as abrirEscreverEmailAction,
  adicionarEmailTo as adicionarEmailToAction,
} from "../../../../../../store/reducers/emails/emailsActions";
import { isAutenticado } from "../../../../../../store/reducers/emails/emails";
import { useUsuario } from "../../../../../../hooks";
import ValidacaoEmail from "../ValidacaoEmail";
import { Flex, createSnackbar } from "../../../../../../_common";
import { copyToClipboard } from "../../../../../../_common/utils/string";
import StatusEmail from "../StatusEmail";

function copiar(value) {
  copyToClipboard(value);
  createSnackbar(defineMessage({ defaultMessage: "Copiado" }));
}

function RowEmail({ emailInfo, classes, contato }) {
  const { isPVOrVendedor } = useUsuario();
  const history = useHistory();

  const podeEnviarEmail = useSelector(isAutenticado);

  const dispatch = useDispatch();

  function abrirEscreverEmail() {
    if (isPVOrVendedor && !podeEnviarEmail) {
      history.push("/spotter/central-emails");
    } else {
      dispatch(adicionarEmailToAction(emailInfo));
      dispatch(abrirEscreverEmailAction());
    }
  }

  if (!emailInfo) {
    return null;
  }

  return (
    <Flex className={classes.root} justifyContent="space-between">
      <Typography color="primary" variant="caption">
        {emailInfo.email}
      </Typography>
      <Flex className={classes.actions}>
        <Flex className={classes.hide}>
          {isPVOrVendedor && <MailOutlined onClick={abrirEscreverEmail} fontSize="small" color="primary" />}
          <FileCopyOutlined onClick={() => copiar(emailInfo?.email)} fontSize="small" color="primary" />
        </Flex>

        {!emailInfo.probabilidadeEntrega && (
          <>
            {typeof emailInfo.valido === "boolean" ? (
              <Flex>
                {emailInfo.valido ? (
                  <Tooltip title="Contato válido">
                    <Check fontSize="small" className={classes.success} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Contato inválido">
                    <Close fontSize="small" color="error" className={classes.error} />
                  </Tooltip>
                )}
              </Flex>
            ) : (
              <Flex>
                {!emailInfo.validando ? (
                  <Flex className={classes.hide}>{<ValidacaoEmail value={emailInfo} contato={contato} />}</Flex>
                ) : (
                  <Tooltip title="Validando">
                    <CircularProgress className={classes.loader} />
                  </Tooltip>
                )}
              </Flex>
            )}
          </>
        )}
        {emailInfo.probabilidadeEntrega && <StatusEmail probabilidadeEntrega={emailInfo.probabilidadeEntrega} />}

        {emailInfo.mineracao === ESTADO_MINERACAO.MINERADO && (
          <React.Fragment>
            <Tooltip title="E-mail originado do minerador">
              <FindInPage className={classes.iconDisabled} />
            </Tooltip>
          </React.Fragment>
        )}
      </Flex>
    </Flex>
  );
}

RowEmail.propTypes = {
  emailInfo: PropTypes.object,
  classes: PropTypes.object,
  contato: PropTypes.object,
};

export default withStyles({
  error: { height: 21, width: 21, cursor: "default" },
  success: { color: "green", height: 21, width: 21, cursor: "default " },
  icon: { height: 21, width: 21 },
  iconDisabled: { color: "rgba(0,0,0,0.54)", height: 21, width: 21 },
  loader: { height: "16px !important", width: "16px !important", marginRight: 2, marginLeft: 2 },
  root: {
    padding: "8px 20px",
    "&:hover": {
      backgroundColor: "rgb(247,247,247)",
    },
    "&:hover $hide": {
      visibility: "visible",
    },
  },
  actions: {
    "& > *": {
      marginRight: 6,
      fontSize: 18,
      cursor: "pointer",
    },
  },
  hide: {
    visibility: "hidden",
  },
})(RowEmail);
