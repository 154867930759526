import React from "react";
import PropTypes from "prop-types";
import { Form } from "formik";

import { Flex } from "../../../../_common";
import CamposPersonalizadosField from "./CamposPersonalizadosField";

function FormCamposPersonalizados({ values, entityId, isOrganizacao }) {
  return (
    <Form>
      <Flex flexDirection="column">
        {values.camposPersonalizados.map((campo, i) => (
          <CamposPersonalizadosField
            name={`camposPersonalizados.${i}`}
            campo={{ ...campo, entityId }}
            key={campo.id}
            isOrganizacao={isOrganizacao}
          />
        ))}
      </Flex>
    </Form>
  );
}

FormCamposPersonalizados.propTypes = {
  values: PropTypes.object,
  /** Id do Lead ou da Organização */
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isOrganizacao: PropTypes.bool,
};

export default FormCamposPersonalizados;
