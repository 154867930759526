import React, { useRef } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import useSWR from "swr";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { withStyles } from "@material-ui/core";
import axios from "axios";

import { CardOutline, NonCardOutline } from "../Outline";
import { ActionButton } from "../../../../components";
import { Loading, createSnackbar, Tipografia, AjaxBlackout } from "../../../../_common";
import createSnackAPIException from "../../../../_common/utils/snackbar/createSnackbarAPIException";
import Arquivos from "./Arquivos";

ArquivosOutline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  card: PropTypes.bool,
  lead: PropTypes.object,
};

function ArquivosOutline({ id, card, lead, classes }) {
  const intl = useIntl();
  const inputRef = useRef(null);
  const { data: arquivos, isValidating, mutate } = useSWR(`/api/pipeline/lead/ListarArquivos?leadId=${id}`);
  const tamanhoMaximo = 15728640;

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = async event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    if (validarTamanho(fileObj)) {
      AjaxBlackout.Show();
      try {
        const formData = new FormData();
        formData.append("File", fileObj);
        await axios.post(`/api/pipeline/lead/UploadArquivo?leadId=${id}`, formData);
        mutate();
        createSnackbar(intl.formatMessage({ defaultMessage: "Arquivo anexado com sucesso" }));
      } catch (err) {
        createSnackAPIException(err);
      } finally {
        AjaxBlackout.Hide();
      }
    }
  };

  const validarTamanho = file => {
    if (file.size > tamanhoMaximo) {
      createSnackbar(intl.formatMessage({ defaultMessage: "Tamanho máximo de solicitação excedido" }));
      return false;
    }
    return true;
  };

  const Outline = card ? CardOutline : NonCardOutline;

  return (
    <Outline
      id="card-Arquivos"
      action={
        <Loading size={24} isLoading={false} classes={{ loading: classes.loading }}>
          <ActionButton
            id="btn-add-arquivos"
            leads={lead}
            type="icon"
            icon={<AttachFileIcon />}
            tooltip={intl.formatMessage({ defaultMessage: "Anexar arquivos" })}
            onClick={() => handleClick()}
          >
            <input id="file-select" type="file" />
          </ActionButton>
        </Loading>
      }
      title={intl.formatMessage({ defaultMessage: "Arquivos" })}
    >
      {isValidating && <Loading isLoading />}
      {!isValidating && (
        <>
          <div className={classes.content}>
            <Arquivos arquivos={arquivos} refresh={mutate} />
            <input style={{ display: "none" }} ref={inputRef} type="file" onChange={handleFileChange} />
          </div>
          <div className={classes.messageContent}>
            <Tipografia cor="darkSecondaryText" className={classes.message}>
              {intl.formatMessage({ defaultMessage: "Limite de 15Mb por anexo" })}
            </Tipografia>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={"https://exactsal.es/armazenar-arquivos"} target="_blank" className={classes.link}>
              {intl.formatMessage({ defaultMessage: "Saiba mais sobre armazenamento de arquivos" })}
            </a>
          </div>
        </>
      )}
    </Outline>
  );
}

export default withStyles({
  content: { maxHeight: "240px", minHeight: "38px", overflow: "auto" },
  message: { paddingTop: 8, position: "absolute" },
  messageContent: { height: 38 },
  loading: { height: "auto" },
  link: {
    marginTop: 24,
    position: "absolute",
    paddingTop: "4px",
    textDecoration: "underline",
    fontStyle: "italic",
    fontSize: "11px",
  },
})(ArquivosOutline);
