import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { useFormikContext } from "formik";
import { mutate } from "swr";
import PropTypes from "prop-types";
import axios from "axios";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText } from "@material-ui/core";
import { withAjaxBlackoutErrorMessage } from "../../../../../../_common/utils/blackout/blackout";
import { createSnackbar } from "../../../../../../_common";

function ModalBloquearContato({ id, callback = () => null }) {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext();
  const { leadId } = useParams();

  async function bloquear() {
    withAjaxBlackoutErrorMessage(async () => {
      await axios.post(`/api/pipeline/contatos/bloquearContato?contatoId=${id}`);
      if (Array.isArray(values.contatos)) {
        const data = values.contatos.filter(contato => contato.id !== id);
        setFieldValue("contatos", data);
      }
      mutate(`/api/pipeline/lead/contatos?id=${leadId}`);

      createSnackbar(
        intl.formatMessage({
          defaultMessage: "Contato bloqueado com sucesso",
        })
      );
      callback();
    });
  }

  return (
    <Fragment>
      <DialogTitle>
        {intl.formatMessage({
          defaultMessage: "Bloquear contato",
        })}
      </DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <DialogContentText>
          {intl.formatMessage({
            defaultMessage:
              "Ao bloquear um contato não será mais possível contatá-lo ou alterar seus dados. O contato pode ser desbloqueado na lista de leads.",
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="btn-cancelar-bloquear-contato" onClick={() => callback(false)}>
          {intl.formatMessage({ defaultMessage: "Cancelar" })}
        </Button>
        <Button id="btn-bloquear-contato" onClick={bloquear} color="primary">
          {intl.formatMessage({ defaultMessage: "Bloquear" })}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

ModalBloquearContato.propTypes = {
  id: PropTypes.number,
  callback: PropTypes.func,
};

export default ModalBloquearContato;
