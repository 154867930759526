import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, withStyles } from "@material-ui/core";
import { useIntl } from "react-intl";

import VincularOrganizacaoModal from "./Modal";
import { ActionButton } from "../../../../components";
import { DialogTitleClose } from "../../../../_common";

function VincularOrganizacao({ callback = x => x, children, ids, filtros, classes, ...props }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  return (
    <Fragment>
      <ActionButton
        id="btn-vincular-organizacao"
        type="text"
        label={intl.formatMessage({ defaultMessage: "Vincular Organização" })}
        tooltip={intl.formatMessage({ defaultMessage: "Vincular Organização" })}
        onClick={() => setModalOpen(true)}
        {...props}
      />
      {modalOpen && (
        <Dialog
          open={modalOpen}
          onClose={() => close(false)}
          maxWidth="sm"
          fullWidth
          classes={{ paper: classes.paper }}
        >
          <DialogTitleClose onClose={() => close(false)}>
            {intl.formatMessage({ defaultMessage: "Vincular Organização" })}
          </DialogTitleClose>
          <VincularOrganizacaoModal
            ids={ids}
            onCancel={close}
            onConfirm={close}
            filtrosAplicados={filtros}
            {...props}
          />
        </Dialog>
      )}
    </Fragment>
  );
}

VincularOrganizacao.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.func,
  filtros: PropTypes.object,
  ids: PropTypes.array,
};

export default withStyles({
  paper: {
    overflowY: "visible",
  },
})(VincularOrganizacao);
