import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button, withStyles, MuiThemeProvider } from "@material-ui/core";

import { useIntl } from "react-intl";
import Outline from "../Outline";
import { Flex, Loading } from "../../../../_common";
import Form from "./FormCamposPersonalizados";
import useCamposPersonalizados from "./useCamposPersonalizados";
import CamposPersonalizados from "./CamposPersonalizados";
import { usePermission } from "../../../../hooks";
import permissions from "../../../../_common/permissions";

const isDetalheLeadScreen = () => window.location.pathname.startsWith(`/spotter/detalhes-lead/`);

function CamposOutline({ entityId, lead, isEditing, isOrganizacao = false, classes, ...props }) {
  const [camposPersonalizados, loading, salvar] = useCamposPersonalizados(entityId, false, isOrganizacao);
  const [expandir, setExpandir] = useState(false);
  const intl = useIntl();
  const multiplosCampos = camposPersonalizados.length > 3;

  const canEditCampoLead = usePermission(permissions.ALTERAR_CAMPOS_PERSONALIZADOS, lead);
  const expandirLabel = expandir
    ? intl.formatMessage({ defaultMessage: "Menos" })
    : intl.formatMessage({ defaultMessage: "Mais" });

  const handleExpandir = () => {
    setExpandir(!expandir);
  };

  if (!loading && !camposPersonalizados.length) return null;

  const camposPersonalizadosResumo = multiplosCampos ? camposPersonalizados.slice(0, 3) : camposPersonalizados;

  return (
    <MuiThemeProvider
      theme={theme => ({ ...theme, overrides: { ...theme.overrides, MuiFormControl: { root: { marginBottom: 16 } } } })}
    >
      <Outline
        title={intl.formatMessage({
          defaultMessage: "Campos Personalizados",
        })}
        initialValues={{ camposPersonalizados }}
        id="card-CampoPersonalizado"
        editButtonId="btn-edit-campos-personalizados"
        isEditing={isEditing}
        onSubmit={({ camposPersonalizados: c }) =>
          salvar(c).then(success => {
            if (isDetalheLeadScreen()) {
              document.dispatchEvent(
                new CustomEvent("detalhe-lead/timeline/reloadLogFilterTriggers", {
                  detail: { flag: success },
                })
              );
            }
            return success;
          })
        }
        form={isOrganizacao ? Form : canEditCampoLead && Form}
        entityId={entityId}
        isOrganizacao={isOrganizacao}
        validateOnChange={false}
        validateOnBlur={false}
        {...props}
      >
        {loading ? (
          <Loading isLoading />
        ) : (
          <>
            <Flex className={`${classes.conteudo} ${!expandir ? classes.addDegrade : ""}`} flexDirection="column">
              <CamposPersonalizados campos={expandir ? camposPersonalizados : camposPersonalizadosResumo} />
            </Flex>
            <Grid item xs={12}>
              <span>
                <Button
                  className={classes.btnGrid}
                  style={{ display: multiplosCampos ? "block" : "none" }}
                  color="primary"
                  id="buttonExpandir"
                  onClick={handleExpandir}
                >
                  {expandirLabel}
                </Button>
              </span>
            </Grid>
          </>
        )}
      </Outline>
    </MuiThemeProvider>
  );
}

CamposOutline.propTypes = {
  /** Id do Lead ou da Organização */
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lead: PropTypes.object,
  isOrganizacao: PropTypes.bool,
  classes: PropTypes.object,
  isEditing: PropTypes.func,
};

export default withStyles({
  conteudo: {
    whiteSpace: "pre-line",
    textAlign: "justify",
  },
  addDegrade: {
    textAlign: "justify",
    position: "relative",
    overflow: "hidden",
  },
  btnGrid: {
    paddingTop: 16,
    paddingLeft: 20,
  },
})(CamposOutline);
