import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect, useDispatch } from "react-redux";
import { compose } from "recompose";
import useSWR from "swr";
import { useIntl } from "react-intl";
import { Dialog } from "@material-ui/core";

import Outline from "../Outline";
import { Loading, createSnackbar, Flex, createSnackbarAPIException } from "../../../../_common";
import { usePermission, useTipoPlanoSpotter, useUsuario } from "../../../../hooks";
import schema from "./ContatosSchema";
import FormContatos, { emptyContact } from "./FormContatos";
import LeadContatos from "./Contatos";
import ContatosNovo from "./ContatosNovo";
import permissions from "../../../../_common/permissions";
import BuscarContatos from "./BuscarContatos";
import {
  validarContato,
  buscarListaContatos,
  salvarListaContatos,
} from "../../../../store/reducers/detalhesLead/detalhesLeadActions";
import LigacaoContatosLead from "../../../detalhes-lead/components/LigacaoContatosLead/LigacaoContatosLead";
import AdicionarContato from "./components/AdicionarContato/AdicionarContato";
import ModalContato from "./components/ModalContato/ModalContato";

function useContatos(id, salvarLista, listaContatos) {
  const URL = `/api/pipeline/lead/contatos?id=${id}`;
  const { data, isValidating: loading, mutate } = useSWR(URL);
  const [contatos, setContatos] = useState([]);
  const intl = useIntl();

  const validarCargo = form => {
    const tamanhoLista = form.length;
    let somenteNumeros = false;
    let possuiArroba = false;

    let retorno = true;
    for (let index = 0; index < tamanhoLista; index++) {
      if (form[index].cargoObj?.descricao !== undefined && form[index].cargoObj?.descricao !== null) {
        somenteNumeros =
          // eslint-disable-next-line no-restricted-globals
          !isNaN(parseFloat(form[index].cargoObj?.descricao)) && isFinite(form[index].cargoObj?.descricao);

        possuiArroba = form[index].cargoObj?.descricao.includes("@");
      }
      if (somenteNumeros || possuiArroba) retorno = false;
    }

    return retorno;
  };

  const save = async form => {
    let success = false;

    if (validarCargo(form)) {
      try {
        await axios.post(URL, form);
        mutate();
        createSnackbar(intl.formatMessage({ defaultMessage: "Alterações salvas com sucesso" }));
        document.dispatchEvent(new Event("detalhe-lead/painel/reload"));
        document.dispatchEvent(
          new CustomEvent("detalhe-lead/timeline/reloadLogFilterTriggers", { detail: { flag: true } })
        );
        success = true;
      } catch (err) {
        success = false;
        createSnackbarAPIException(err);
      }
    }

    return success;
  };

  useEffect(
    () => {
      setContatos(data);
      salvarLista(data);
    },
    [data]
  );

  useEffect(
    () => {
      setContatos(listaContatos);
    },
    [listaContatos]
  );

  return [contatos, loading, save, mutate];
}

ContatosOutline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lead: PropTypes.object,
  classes: PropTypes.object,
  listaContatos: PropTypes.array,
  buscarLista: PropTypes.func,
  salvarLista: PropTypes.func,
  validar: PropTypes.func,
  isEditing: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  buscarLista: id => dispatch(buscarListaContatos(id)),
  salvarLista: contato => dispatch(salvarListaContatos(contato)),
  validar: (value, contato) => dispatch(validarContato(value, contato)),
});

const mapStateToProps = state => ({
  listaContatos: state.detalhesLead.contatos,
});

function ContatosOutline({ id, lead, classes, buscarLista, salvarLista, validar, listaContatos, isEditing, ...props }) {
  const { data: config, error: configError } = useSWR(`/api/pipeline/lead/ConfiguracoesCadastro`);
  const isLoadingConfig = !config && !configError;
  const [contatos, loading, salvar, refetch] = useContatos(id, salvarLista, listaContatos);
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useDispatch();
  const intl = useIntl();
  const [modalLigacaoIsOpen, setModalLigacaoIsOpen] = useState(false);
  const [contatoSelecionado, setContatoSelecionado] = useState(null);
  const [isModalEditarContatoOpen, setIsModalEditarContatoOpen] = useState(false);
  const isSpotterFull = useTipoPlanoSpotter();
  const { featureToggles } = useUsuario();
  const limiteEdicaoMultiplaContatos = 20;

  useEffect(
    () => {
      if (!contatos.length) {
        setInitialValues({ contatos: [emptyContact(true)] });
        return;
      }

      setInitialValues({ contatos });
    },
    [contatos]
  );

  const canEdit = usePermission(permissions.ALTERAR_CONTATOS, lead);

  function retirarMascara(numero) {
    return numero
      .toString()
      .replace(/[( )-]+/g, "")
      .trim();
  }

  function atualizarListaContatos(listaContatosAlterada) {
    setInitialValues({ contatos: listaContatosAlterada });
  }

  function onCancel() {
    setInitialValues({ contatos });
  }

  const onClose = () => {
    setIsModalEditarContatoOpen(false);
  };

  return (
    <Outline
      {...props}
      title={intl.formatMessage({
        defaultMessage: "Contatos",
      })}
      form={canEdit ? FormContatos : null}
      lead={lead}
      id="card-Contatos"
      initialValues={initialValues}
      editButtonId="btn-edit-contatos"
      isEditing={isEditing}
      onSubmit={({ contatos: c }) =>
        salvar(
          c.map(item => ({
            ...item,
            emails: item.emails.filter(email => email.endereco),
            telefones: item.telefones
              .filter(tel => tel.numero)
              .map(tel => ({ ...tel, numero: retirarMascara(tel.numero) })),
            mensageiros: item.mensageiros.filter(men => men.usuario),
          })),
          dispatch
        )
      }
      schema={schema}
      loading={isLoadingConfig || loading}
      exigirNomeTelefone={config?.exigirNomeTelefone}
      atualizarListaContatos={atualizarListaContatos}
      onCancel={onCancel}
      listaContatos={contatos}
      limiteEdicaoMultiplaContatos={limiteEdicaoMultiplaContatos}
    >
      {loading && <Loading isLoading />}

      {modalLigacaoIsOpen && <LigacaoContatosLead onClose={() => setModalLigacaoIsOpen(false)} leadId={id} />}
      {!loading && (
        <>
          {featureToggles.rolloutBuscadorValidador && (
            <ContatosNovo
              lead={lead}
              listaContatos={contatos}
              leadId={id}
              setModalLigacaoIsOpen={setModalLigacaoIsOpen}
              setContatoSelecionado={setContatoSelecionado}
              setIsModalEditarContatoOpen={setIsModalEditarContatoOpen}
              limiteEdicaoMultiplaContatos={limiteEdicaoMultiplaContatos}
            />
          )}
          {!featureToggles.rolloutBuscadorValidador && (
            <LeadContatos
              lead={lead}
              listaContatos={contatos}
              leadId={id}
              setModalLigacaoIsOpen={setModalLigacaoIsOpen}
              setContatoSelecionado={setContatoSelecionado}
              setIsModalEditarContatoOpen={setIsModalEditarContatoOpen}
              limiteEdicaoMultiplaContatos={limiteEdicaoMultiplaContatos}
            />
          )}
        </>
      )}
      <Flex justifyContent="flex-start">
        {!!lead && isSpotterFull && <BuscarContatos lead={lead} callback={refetch} />}
        {listaContatos.length > limiteEdicaoMultiplaContatos && <AdicionarContato lead={lead} />}
      </Flex>

      <Dialog open={isModalEditarContatoOpen} onClose={onClose}>
        <ModalContato onClose={onClose} contato={contatoSelecionado} lead={lead} leadId={lead?.id} />
      </Dialog>
    </Outline>
  );
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(ContatosOutline);
