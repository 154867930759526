import React from "react";
import PropTypes from "prop-types";
import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useIntl } from "react-intl";
import axios from "axios";
import { mutate } from "swr";
import { Formik } from "formik";

import FormContato from "../../FormContato";
import { createSnackbar, createSnackbarAPIException, Flex } from "../../../../../../_common";

ModalContato.propTypes = {
  lead: PropTypes.object,
  leadId: PropTypes.number,
  contato: PropTypes.object,
  cadastro: PropTypes.bool,
  exigirNomeTelefone: PropTypes.bool,
  onClose: PropTypes.func,
};

function ModalContato({ lead, leadId, contato, cadastro = false, exigirNomeTelefone = false, onClose }) {
  const intl = useIntl();
  const urlCadastro = `/api/pipeline/lead/contatos?id=${leadId}&removerNaoEnviados=false`;
  const urlEdicao = `/api/pipeline/lead/contato?id=${leadId}`;

  function retirarMascara(numero) {
    return numero
      .toString()
      .replace(/[( )-]+/g, "")
      .trim();
  }

  const validarCargo = values => {
    let somenteNumeros = false;
    let possuiArroba = false;
    let retorno = true;

    if (values.cargoObj?.descricao !== undefined && values.cargoObj?.descricao !== null) {
      somenteNumeros =
        // eslint-disable-next-line no-restricted-globals
        !isNaN(parseFloat(values.cargoObj?.descricao)) && isFinite(values.cargoObj?.descricao);

      possuiArroba = values.cargoObj?.descricao.includes("@");
    }
    if (somenteNumeros || possuiArroba) retorno = false;

    return retorno;
  };

  async function submit(values) {
    if (validarCargo(values)) {
      try {
        const contatoFormatado = {
          ...values.contato,
          emails: values.contato.emails.filter(email => email.endereco),
          telefones: values.contato.telefones.filter(tel => tel.numero).map(tel => ({
            ...tel,
            numero: retirarMascara(tel.numero),
          })),
          mensageiros: values.contato.mensageiros.filter(men => men.usuario),
        };

        const url = cadastro ? urlCadastro : urlEdicao;
        const payload = cadastro ? [contatoFormatado] : contatoFormatado;
        await axios.post(url, payload);
        createSnackbar(intl.formatMessage({ defaultMessage: "Alterações salvas com sucesso." }));
        mutate(`/api/pipeline/lead/contatos?id=${leadId}`);
        onClose(false);
      } catch (err) {
        createSnackbarAPIException(err);
      }
    }
  }

  return (
    <Flex flexDirection="column" style={{ width: 756, overflowX: "hidden" }}>
      <DialogTitle>
        {cadastro
          ? intl.formatMessage({ defaultMessage: "Adicionar contato" })
          : intl.formatMessage({ defaultMessage: "Editar contato" })}
      </DialogTitle>
      <Formik initialValues={{ contato }} onSubmit={submit}>
        {({ values, setValues, isSubmitting, submitForm }) => (
          <>
            <DialogContent>
              <FormContato
                values={values}
                setValues={setValues}
                contato={contato}
                lead={lead}
                leadId={leadId}
                cadastro={cadastro}
                exigirNomeTelefone={exigirNomeTelefone}
                onClose={onClose}
                submit={submit}
                isSubmitting={isSubmitting}
              />
            </DialogContent>
            <DialogActions>
              <Button id="btn-cancelar-edicao-contato" onClick={() => onClose(false)}>
                {intl.formatMessage({ defaultMessage: "Cancelar" })}
              </Button>
              <Button
                id="btn-salvar-edicao-contato"
                color="primary"
                onClick={() => submitForm()}
                disabled={isSubmitting}
              >
                {intl.formatMessage({ defaultMessage: "Salvar" })}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Flex>
  );
}

export default ModalContato;
