import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, withStyles } from "@material-ui/core";
import { useIntl } from "react-intl";
import AddIcon from "@material-ui/icons/Add";

import { Flex } from "../../../../../../_common";
import { ActionButton } from "../../../../../../components";
import { GET_DEFAULT_TELEFONE } from "../../../Telefones/Telefones";
import { GET_DEFAULT_MENSAGEIRO } from "../../../Mensageiros";
import { GET_DEFAULT_EMAIL } from "../../../Emails/Emails";
import { BASE_LEGAL } from "../../../../../../_common/constantes";
import ModalContato from "../ModalContato/ModalContato";

export const emptyContact = {
  nome: "",
  cargoObj: { id: null, descricao: "" },
  telefones: [GET_DEFAULT_TELEFONE()],
  mensageiros: [GET_DEFAULT_MENSAGEIRO()],
  emails: [GET_DEFAULT_EMAIL()],
  principal: false,
  coletaDados: BASE_LEGAL.NENHUMA,
  envioComunicacao: BASE_LEGAL.NENHUMA,
  dadosVoz: BASE_LEGAL.NENHUMA,
};

AdicionarContato.propTypes = {
  lead: PropTypes.object,
};

function AdicionarContato({ lead, classes }) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const toggle = () => setOpen(!open);
  if (!lead) return null;

  function onClose() {
    toggle();
  }

  return (
    <Flex className={classes.newLayout}>
      <ActionButton
        id="btn-adicionar-contato"
        type="button"
        color="primary"
        label={intl.formatMessage({
          defaultMessage: "Adicionar Contato",
        })}
        onClick={toggle}
        icon={<AddIcon style={{ marginRight: 4 }} />}
        className={classes.noPadding}
      />

      <Dialog open={open} onClose={onClose}>
        <ModalContato onClose={onClose} contato={emptyContact} lead={lead} leadId={lead.id} cadastro />
      </Dialog>
    </Flex>
  );
}

export default withStyles(() => ({
  newLayout: { padding: "0px 20px" },
  noPadding: { padding: 0 },
}))(AdicionarContato);
