import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { withStyles, Divider, Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import moment from "moment";
import { DEFAULT_COUNTRY } from "../../../../_common/utils/phone/maskConfig";

import { Flex, InfoItem, Tipografia } from "../../../../_common";
import { Fone, Site /* Email */ } from "../../../../components";
import { formatPhoneMask } from "../../../../_common/utils/phone";
import { cpfMask, cnpjMask, cpf } from "../../../../components/TextMaskCpfCnpj";
import LigacaoContatosLead from "../../../detalhes-lead/components/LigacaoContatosLead/LigacaoContatosLead";
import SearchingInfo from "../ConsultarSearching/ConsultarSearching";
import ConsultarBotao from "../ConsultarSearching/BotaoConsultar";

function Empresa({ classes, habilitaCpfCnpj, isPublic, ...lead }) {
  const [mostrarMais, setMostrarMais] = useState(false);
  const intl = useIntl();
  const [modalLigacaoIsOpen, setModalLigacaoIsOpen] = useState(false);
  const ehCNPJ = lead?.cpfCnpj?.length > cpf.length - 3;

  return (
    <Fragment>
      <div id="leadEmpresa">
        <Tipografia tipo="textoSidebar" className={classes.nomeLead}>
          {lead.nome}
        </Tipografia>
        {modalLigacaoIsOpen && <LigacaoContatosLead onClose={() => setModalLigacaoIsOpen(false)} leadId={lead.id} />}
        {lead.telefones &&
          lead.telefones.map(telefone => (
            <Fone
              key={telefone.id}
              value={formatPhoneMask({
                phone: telefone.numero,
                ddi: telefone.ddi === "" ? DEFAULT_COUNTRY : telefone.ddi,
              })}
              ddi={telefone.ddi}
              leadId={lead.id}
              tipo={String(telefone.tipo.id)}
              linkMensagensTallos={telefone.linkMensagensTallos}
              setModalLigacaoIsOpen={setModalLigacaoIsOpen}
            />
          ))}
        {/* {lead.emails && lead.emails.map(email => <Email key={email.id} value={{ email: email.endereco }} />)} */}
        {lead.site && <Site value={lead.site} />}

        {mostrarMais && (
          <Fragment>
            <div id="leadEmpresaMostrarMais">
              <Divider className={classes.divider} />
              {lead.linkRd && (
                <Fragment>
                  <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.sideSpace}>
                    Link RD Station
                  </Tipografia>
                  <Site value={lead.linkRd} />
                </Fragment>
              )}

              <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.sideSpace}>
                {intl.formatMessage({ defaultMessage: "Link Público" })}
              </Tipografia>
              <Site value={lead.linkPublico} />

              <Divider className={classes.divider} />

              <Flex justifyContent="space-between" style={{ flexWrap: "wrap" }}>
                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Origem" })}
                  value={lead.origem ? lead.origem.descricao : "-"}
                  id="id-origem-empresa"
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Sub-Origem" })}
                  value={lead.subOrigem ? lead.subOrigem.descricao : "-"}
                  id="id-sub-origem-empresa"
                />

                <InfoItem
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Produto do Lead" })}
                  value={lead.produto}
                  id="id-produto-lead-empresa"
                />

                <InfoItem
                  id="id-mercado-empresa"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Mercado" })}
                  value={lead.mercado ? lead.mercado.descricao : "-"}
                />

                <InfoItem
                  id="dt-previsao-fechamento"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Previsão de fechamento" })}
                  value={lead.dtPrevisaoFechamento ? moment(lead.dtPrevisaoFechamento).format("DD/MM/YYYY") : "-"}
                />

                <InfoItem
                  id="id-cep-empresa"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "CEP" })}
                  value={lead.endereco && lead.endereco.cep ? lead.endereco.cep : "-"}
                />

                <InfoItem
                  id="id-pais-empresa"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "País" })}
                  value={lead.endereco && lead.endereco.pais ? lead.endereco.pais.descricao : "-"}
                />

                <InfoItem
                  id="id-estado-empresa"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Estado" })}
                  value={lead.endereco && lead.endereco.estado ? lead.endereco.estado.descricao : "-"}
                />
                <InfoItem
                  id="id-cidade-empresa"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Cidade" })}
                  value={lead.endereco && lead.endereco.cidade ? lead.endereco.cidade.descricao : "-"}
                />

                <InfoItem
                  id="id-logradouro-empresa"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Logradouro" })}
                  value={lead.endereco && lead.endereco.logradouro ? lead.endereco.logradouro : "-"}
                />

                <InfoItem
                  id="id-numero-empresa"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Número" })}
                  value={lead.endereco && lead.endereco.numero ? lead.endereco.numero : "-"}
                />

                <InfoItem
                  id="id-complemento-empresa"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Complemento" })}
                  value={lead.endereco && lead.endereco.complemento ? lead.endereco.complemento : "-"}
                />

                <InfoItem
                  id="id-bairro-empresa"
                  className={classes.campo}
                  label={intl.formatMessage({ defaultMessage: "Bairro" })}
                  value={lead.endereco && lead.endereco.bairro ? lead.endereco.bairro : "-"}
                />

                {habilitaCpfCnpj && (
                  <InfoItem
                    id="id-cpf-cnpj-empresa"
                    className={classes.campo}
                    label={intl.formatMessage({ defaultMessage: "CPF/CNPJ" })}
                    value={ehCNPJ ? cnpjMask(lead?.cpfCnpj) : cpfMask(lead?.cpfCnpj)}
                    InfoComponent={
                      ehCNPJ && !isPublic ? props => <SearchingInfo {...props} cnpj={lead?.cpfCnpj} /> : null
                    }
                    InfoButton={ConsultarBotao}
                  />
                )}
              </Flex>
            </div>
          </Fragment>
        )}

        <Flex justifyContent="space-between" className={classes.actions}>
          <Button color="primary" onClick={() => setMostrarMais(m => !m)} id="buttonMaisEmpresa">
            {mostrarMais
              ? intl.formatMessage({ defaultMessage: "Menos" })
              : intl.formatMessage({ defaultMessage: "Mais" })}
          </Button>
        </Flex>
      </div>
    </Fragment>
  );
}

Empresa.propTypes = {
  habilitaCpfCnpj: PropTypes.bool,
  isPublic: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles({
  campo: { width: "50% !important" },
  nomeLead: { padding: "8px 20px", wordBreak: "break-all" },
  divider: { margin: "0 20px 8px" },
  sideSpace: { paddingLeft: 20, paddingRight: 20 },
  actions: { margin: "18px 8px 8px", padding: "0 8px", justifySelf: "flex-end" },
})(Empresa);
