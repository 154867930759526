import { createContext } from "react";
import { defineMessage } from "react-intl";

export default createContext({
  mercados: [],
  origens: [],
  subOrigens: [],
  dores: [],
  produtos: [],
  tiposDeTelefone: [
    { id: 1, descricao: defineMessage({ defaultMessage: "Comercial" }) },
    { id: 2, descricao: defineMessage({ defaultMessage: "Celular" }) },
  ],
  mensageiros: [
    { id: 1, descricao: "Appear.in" },
    { id: 8, descricao: "Facebook" },
    { id: 2, descricao: "GoToMeeting" },
    { id: 3, descricao: "Hangouts" },
    { id: 9, descricao: "Instagram" },
    { id: 7, descricao: "LinkedIn" },
    { id: 4, descricao: "Skype" },
    { id: 10, descricao: "Twitter" },
    { id: 11, descricao: "TikTok" },
    { id: 5, descricao: "WebEX" },
    { id: 6, descricao: "Outros" },
  ],
  cidades: [],
  estados: [],
  paises: [],
});
