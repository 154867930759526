import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { get } from "dot-prop-immutable";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import Moment from "moment";
import { Button, DialogTitle, DialogContent, DialogActions, withStyles, Icon } from "@material-ui/core";
import LabelIcon from "@material-ui/icons/Label";
import PersonIcon from "@material-ui/icons/Person";
import PlaceIcon from "@material-ui/icons/Place";
import WorkIcon from "@material-ui/icons/Work";
import axios from "axios";
import useSWR from "swr";

import { Loading, Flex, createSnackbarAPIException, ExpansionPanelModal, AjaxBlackout } from "../../../../_common";
import { useUsuario, useGet, useLeadsSemelhantes, useTipoPlanoSpotter } from "../../../../hooks";
import { Telefones } from "../";
import { FormikErrorScroller } from "../../../../components";
import FormContatos, { emptyContact } from "../contatos/FormContatos";
import CamposPersonalizadosField from "../camposPersonalizados/CamposPersonalizadosField";
import { CAMPOS_LEADS_SEMELHANTES, PAIS_BRASIL } from "../../../../_common/constantes";
import CampoSemelhantes from "../CampoSemelhantes";
import CampoCpfCnpj from "../../../../components/CampoCpfCnpj";
import LeadEnderecoForm from "../EnderecoForm";
import { defaultGetElement } from "../../../../components/FormikErrorScroller";
import LeadSelectOrganizacao from "../SelectOrganizacao/SelectOrganizacao";
import LeadsSemelhantes from "../LeadsSemelhantes/LeadsSemelhantes";
import { GET_DEFAULT_TELEFONE } from "../Telefones/Telefones";
import {
  AsyncSelectMercado,
  AsyncSelectPreVendedor,
  AsyncSelectOrigem,
  AsyncSelectFunil,
  AsyncSelectSubOrigem,
} from "../../../../components/AsyncSelect";

const initializeFormValues = (pv, funilPadraoId, campos = [], exigirNomeTelefone) => ({
  nome: "",
  site: "",
  linkRd: "",
  origem: { id: "" },
  subOrigem: { id: "" },
  mercado: { id: "" },
  funilId: funilPadraoId,
  preVendedorId: pv,
  telefones: [GET_DEFAULT_TELEFONE()],
  contatos: exigirNomeTelefone ? [emptyContact(true)] : [],
  dores: [],
  produto: "",
  cpfCnpj: "",
  organizacao: { id: null, nome: "" },
  endereco: {
    pais: { ...PAIS_BRASIL },
    estado: { id: null, descricao: "" },
    cidade: { id: null, descricao: "" },
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cep: "",
  },
  camposPersonalizados: campos,
});

async function validateUniqueCampoPersinalizado(campo) {
  const { id, valor, valores } = campo;
  let isUnique = false;
  try {
    const { data } = await axios.get(
      `/api/pipeline/CampoPersonalizado/ValidarUnicidade?id=${id}&valor=${valor || valores}`
    );
    isUnique = data;
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }

  return !isUnique ? "O valor nesse campo deve ser único entre os leads" : undefined;
}

function ModalCadastrar({ onCancel, onConfirm, initialFormValues, organizacaoDisabled, classes, idBtnCadastrar }) {
  const intl = useIntl();
  const { isGerente, id: idUsuario, isPreVendedor, funilPadraoId } = useUsuario();
  const [campos, isLoadingCampos] = useGet(
    `/api/pipeline/CampoPersonalizado/ListasParaEdicao?removerSomenteApi=true`,
    []
  );
  const isSpotterFull = useTipoPlanoSpotter();
  const { data: config, isValidating: isValidatingConfig } = useSWR("/api/pipeline/lead/ConfiguracoesCadastro");
  const isLoadingConfig = isValidatingConfig;
  const isLoading = isLoadingCampos || isLoadingConfig;
  const isGerenteOrVendedor = isGerente || !isPreVendedor;
  const { configuracoesCpfCnpj, exigirNomeTelefone } = config || {};

  const [initialValues, setInitialValues] = React.useState({});
  const [desabilitarSubOrigem, setDesabilitarSubOrigem] = React.useState(true);

  const validateRequired = (value = "") => {
    if (!value.toString().trim()) {
      return intl.formatMessage({ defaultMessage: "Campo obrigatório *" });
    }

    return "";
  };

  React.useEffect(
    () => {
      setInitialValues({
        ...initializeFormValues(isGerenteOrVendedor ? "" : idUsuario, funilPadraoId, campos, exigirNomeTelefone),
        ...initialFormValues,
      });
    },
    [isGerenteOrVendedor, idUsuario, campos, exigirNomeTelefone]
  );

  const [cpfCnpjPesquisa, setCpfCnpjPesquisa] = useState("");
  const [leadsSemelhantes, isLoadingSemelhantes] = useLeadsSemelhantes(
    CAMPOS_LEADS_SEMELHANTES.CPF_CNPJ,
    cpfCnpjPesquisa,
    null
  );

  function submitHandler(values, { setFieldError, setSubmitting }) {
    async function submit() {
      AjaxBlackout.Show();

      const isValid = await validateCamposPersonalizados(values.camposPersonalizados, setFieldError);

      if (values.mercado.id === "") {
        setSubmitting(false);
        AjaxBlackout.Hide();
        return;
      }

      if (values.preVendedorId === "" || values.origem.id === "") {
        setSubmitting(false);
        AjaxBlackout.Hide();
        return;
      }

      if (!isValid) {
        setSubmitting(false);
        AjaxBlackout.Hide();
        return;
      }

      const lead = {
        ...values,
        cpfCnpj: values.cpfCnpj?.replace(/\D/g, ""),
        telefones: values.telefones
          .filter(telefone => telefone.numero)
          .map(tel => ({ ...tel, numero: retirarMascara(tel.numero) })),
        contatos: values.contatos.map(item => ({
          ...item,
          telefones: item.telefones
            .filter(telefone => telefone.numero)
            .map(tel => ({ ...tel, numero: retirarMascara(tel.numero) })),
          emails: item.emails.filter(email => email.endereco),
          mensageiros: item.mensageiros.filter(men => men.usuario),
        })),
      };

      try {
        const { data: leadId } = await axios.post("/api/pipeline/lead/cadastrar", lead);
        onConfirm(leadId);
        AjaxBlackout.Hide();
      } catch (err) {
        AjaxBlackout.Hide();
        createSnackbarAPIException(err);
        setSubmitting(false);
      }
    }
    /**
     * Implementado dessa forma para evitar a
     * submissão do form múltiplas vezes.
     * Formik não atualiza como esperado a variável
     * isSubmitting quando a função onSubmit é
     * assíncrona.
     * @see https://github.com/formium/formik/issues/1957#issuecomment-638470426
     */
    submit();
  }

  function retirarMascara(numero) {
    return numero
      .toString()
      .replace(/[( )-]+/g, "")
      .trim();
  }

  async function validateCamposPersonalizados(camposPersonalizados, setFieldError) {
    const validacoesPromises = camposPersonalizados.map(async (campo, index) => {
      if (!campo.unico) {
        return null;
      }
      const message = await validateUniqueCampoPersinalizado(campo);
      const fieldName = `camposPersonalizados.${index}`;

      return message ? { fieldName, message } : null;
    });

    if (!validacoesPromises?.length) return true;

    const errors = await Promise.all(validacoesPromises);

    const errorFields = [];

    errors.forEach(errObj => {
      if (!errObj) return;

      const { fieldName, message } = errObj;
      if (message) {
        errorFields.push(fieldName);
        setFieldError(fieldName, message);
      }
    });

    const isValid = errorFields.length <= 0;
    if (!isValid) {
      const field = defaultGetElement(errorFields[0]);
      field.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    return isValid;
  }

  function getDadosLeadHasErrors(errors, submitCount) {
    const fieldNames = [
      "nome",
      "funilId",
      "preVendedorId",
      "origem.id",
      "subOrigem.id",
      "mercado.id",
      "cpfCnpj",
      "telefones",
      "site",
      "linkRd",
      "produto",
      "dtPrevisaoFechamento",
      "organizacao",
    ];
    return submitCount > 0 && fieldNames.some(name => Boolean(get(errors, name)));
  }

  const funcDesabilitarSubOrigem = valor => {
    setDesabilitarSubOrigem(valor);
  };

  return (
    <Flex flexDirection="column" style={{ width: 756 }}>
      <DialogTitle>{intl.formatMessage({ defaultMessage: "Cadastrar Lead/Oportunidade" })}</DialogTitle>
      <Loading isLoading={isLoading}>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={submitHandler}>
          {({ values, isSubmitting, handleReset, handleBlur, errors, submitCount }) => {
            const dadosLeadHasErrors = getDadosLeadHasErrors(errors, submitCount);
            const contatosHasErrors = submitCount > 0 && !!errors.contatos;
            const enderecoHasErrors = submitCount > 0 && !!errors.endereco;
            const observacoesHasErrors = submitCount > 0 && !!errors.observacoes;
            const camposPersonalizadosHasErrors = submitCount > 0 && !!errors.camposPersonalizados;

            return (
              <Form id="form-cadastro-lead" noValidate>
                <FormikErrorScroller />
                <DialogContent className={classes.dialogContent}>
                  <div className={classes.sectionWrapper}>
                    <ExpansionPanelModal
                      defaultExpanded
                      title={intl.formatMessage({ defaultMessage: "Dados do Lead/Oportunidade" })}
                      icon={<WorkIcon />}
                      hasError={dadosLeadHasErrors}
                    >
                      <>
                        <Field
                          fullWidth
                          name="funilId"
                          label={intl.formatMessage({ defaultMessage: "Funil" })}
                          component={AsyncSelectFunil}
                        />

                        <CampoSemelhantes
                          fullWidth
                          campo={CAMPOS_LEADS_SEMELHANTES.LEAD_NOME}
                          label={intl.formatMessage({ defaultMessage: "Nome do Lead/Oportunidade" })}
                          name="nome"
                          required
                          inputProps={{
                            maxLength: 500,
                          }}
                          validate={validateRequired}
                        />
                        {isGerenteOrVendedor && (
                          <Field
                            fullWidth
                            name="preVendedorId"
                            label={
                              isSpotterFull
                                ? intl.formatMessage({ defaultMessage: "Pré-Vendedor" })
                                : intl.formatMessage({ defaultMessage: "Usuário" })
                            }
                            component={AsyncSelectPreVendedor}
                            validate={submitCount > 0 && validateRequired}
                          />
                        )}
                        <Field
                          fullWidth
                          name="origem.id"
                          label={intl.formatMessage({ defaultMessage: "Origem" })}
                          component={AsyncSelectOrigem}
                          desbloquearSubOrigem={funcDesabilitarSubOrigem}
                          validate={submitCount > 0 && validateRequired}
                        />
                        <Field
                          fullWidth
                          name="subOrigem.id"
                          disabled={desabilitarSubOrigem}
                          label={intl.formatMessage({ defaultMessage: "Sub-origem" })}
                          component={AsyncSelectSubOrigem}
                          origem={values.origem.id}
                        />
                        <Field
                          fullWidth
                          name="mercado.id"
                          label={intl.formatMessage({ defaultMessage: "Mercado" })}
                          component={AsyncSelectMercado}
                          validate={submitCount > 0 && validateRequired}
                        />

                        {configuracoesCpfCnpj?.habilitar && (
                          <CampoCpfCnpj
                            name="cpfCnpj"
                            config={configuracoesCpfCnpj}
                            fullWidth
                            isLoading={isLoadingSemelhantes}
                            SemelhantesComponent={<LeadsSemelhantes {...leadsSemelhantes} />}
                            showSemelhantes={leadsSemelhantes?.quantidade > 0}
                            onBlur={event => {
                              setCpfCnpjPesquisa(values?.cpfCnpj.replace(/\D/g, "").trim());
                              handleBlur(event);
                            }}
                          />
                        )}
                        {/* nome do campo pra similares: lead_telefone */}
                        <Telefones name="telefones" />
                        {/* nome do campo pra similares: lead_email */}
                        <CampoSemelhantes
                          fullWidth
                          campo={CAMPOS_LEADS_SEMELHANTES.LEAD_SITE}
                          label={intl.formatMessage({ defaultMessage: "Site" })}
                          name="site"
                          inputProps={{
                            maxLength: 500,
                          }}
                        />
                        <Field
                          fullWidth
                          name="linkRd"
                          component={TextField}
                          label={intl.formatMessage({ defaultMessage: "Link RD Station" })}
                        />
                        <Field
                          fullWidth
                          name="produto"
                          component={TextField}
                          label={intl.formatMessage({ defaultMessage: "Produto do Lead" })}
                          inputProps={{
                            maxLength: 255,
                          }}
                        />

                        <Field
                          id="dtPrevisaoFechamento"
                          name="dtPrevisaoFechamento"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          component={TextField}
                          label={intl.formatMessage({ defaultMessage: "Previsão de fechamento" })}
                          inputProps={{ min: new Moment().format("YYYY-MM-DD") }}
                        />

                        {
                          <Field
                            fullWidth
                            name="organizacao"
                            component={LeadSelectOrganizacao}
                            inputProps={{ maxLength: 255 }}
                            disabled={isSubmitting || organizacaoDisabled}
                            freeSolo
                          />
                        }
                      </>
                    </ExpansionPanelModal>
                  </div>

                  <div className={classes.sectionWrapper}>
                    <ExpansionPanelModal
                      title={intl.formatMessage({ defaultMessage: "Contatos" })}
                      icon={<PersonIcon />}
                      hasError={contatosHasErrors}
                    >
                      <FormContatos values={values} exigirNomeTelefone={exigirNomeTelefone} cadastro />
                    </ExpansionPanelModal>
                  </div>

                  <div className={classes.sectionWrapper}>
                    <ExpansionPanelModal
                      title={intl.formatMessage({ defaultMessage: "Endereço" })}
                      icon={<PlaceIcon />}
                      hasError={enderecoHasErrors}
                    >
                      <LeadEnderecoForm />
                    </ExpansionPanelModal>
                  </div>
                  {isSpotterFull && (
                    <div className={classes.sectionWrapper}>
                      <ExpansionPanelModal
                        title={intl.formatMessage({ defaultMessage: "Campos Personalizados" })}
                        icon={<Icon className="fa fa-pencil-square-o" />}
                        hasError={camposPersonalizadosHasErrors}
                      >
                        <Flex flexDirection="column">
                          {campos &&
                            campos.map((campo, index) => (
                              <span data-scroll-anchor={`camposPersonalizados.${index}`} key={campo.id}>
                                <CamposPersonalizadosField
                                  name={`camposPersonalizados.${index}`}
                                  campo={campo}
                                  shouldValidateUnique={false}
                                />
                              </span>
                            ))}
                        </Flex>
                      </ExpansionPanelModal>
                    </div>
                  )}

                  <div className={classes.sectionWrapper}>
                    <ExpansionPanelModal
                      title={intl.formatMessage({ defaultMessage: "Observações" })}
                      icon={<LabelIcon />}
                      hasError={observacoesHasErrors}
                    >
                      <Field
                        multiline
                        rows={4}
                        component={TextField}
                        name="observacoes"
                        id="observacoes"
                        fullWidth
                        inputProps={{ maxLength: 8000 }}
                        label={intl.formatMessage({ defaultMessage: "Observações" })}
                        validate={val =>
                          val?.length > 8000
                            ? intl.formatMessage({
                                defaultMessage: "Campo deve ter no máximo 8000 caracteres.",
                              })
                            : ""
                        }
                      />
                    </ExpansionPanelModal>
                  </div>
                </DialogContent>

                <DialogActions>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      handleReset();
                      onCancel();
                    }}
                  >
                    {intl.formatMessage({ defaultMessage: "Cancelar" })}
                  </Button>

                  <Button id={idBtnCadastrar} disabled={isSubmitting} color="primary" type="submit">
                    {intl.formatMessage({ defaultMessage: "Cadastrar" })}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Loading>
    </Flex>
  );
}

ModalCadastrar.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  initialFormValues: PropTypes.object,
  organizacaoDisabled: PropTypes.bool,
  idBtnCadastrar: PropTypes.string,
};

export default withStyles({
  expansionPanelRoot: {
    overflow: "visible",
  },
  expansionPanelDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 12,
  },
  dialogContent: {
    paddingTop: "0 !important",
  },
  addContact: { margin: "12px 0 24px" },
  input: {
    flexGrow: 1,
  },
  sectionTitle: {
    marginBottom: 8,
  },
  sectionWrapper: {
    paddingBottom: 16,
  },
  sectionObservacoes: {
    marginTop: 16,
  },
})(ModalCadastrar);
