import React from "react";
import { useIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import axios from "axios";
import { DialogContent, DialogTitle, DialogActions, Button, DialogContentText, withStyles } from "@material-ui/core";
import { withAjaxBlackoutErrorMessage } from "../../../../_common/utils/blackout/blackout";
import { createSnackbar } from "../../../../_common";

const messages = defineMessages({
  reenviarConviteLead: {
    defaultMessage: "Reenviar convite Lead",
  },
  reenviarConviteVendedor: {
    defaultMessage: "Reenviar convite vendedor",
  },
  conviteEnviadoLead: {
    defaultMessage: "O convite da videoconferência já foi enviado para o Lead. Deseja enviar novamente?",
  },
  conviteEnviadoVendedor: {
    defaultMessage: "O convite da videoconferência já foi enviado para o vendedor. Deseja enviar novamente?",
  },
});

function ModalVideoconferenciaURL({ id, vendedor, callback = () => null, classes }) {
  const intl = useIntl();

  async function reenviar() {
    withAjaxBlackoutErrorMessage(async () => {
      await axios.post(`/api/pipeline/Videoconferencia/ReenviarURL${vendedor ? "Vendedor" : "Lead"}?leadId=${id}`);
      createSnackbar("Convite da videoconferência enviado com sucesso");
      callback();
    });
  }

  return (
    <>
      <DialogTitle>
        {vendedor
          ? intl.formatMessage(messages.reenviarConviteVendedor)
          : intl.formatMessage(messages.reenviarConviteLead)}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          {vendedor
            ? intl.formatMessage(messages.conviteEnviadoVendedor)
            : intl.formatMessage(messages.conviteEnviadoLead)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="btn-cancelar-reenvio-videoconferencia" onClick={() => callback(false)}>
          {intl.formatMessage({ defaultMessage: "Cancelar" })}
        </Button>
        <Button id="btn-reenviar-videoconferencia" onClick={reenviar} color="primary">
          {intl.formatMessage({ defaultMessage: "Reenviar" })}
        </Button>
      </DialogActions>
    </>
  );
}

ModalVideoconferenciaURL.propTypes = {
  id: PropTypes.string,
  callback: PropTypes.func,
  vendedor: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles({
  content: {
    display: "flex",
    flexDirection: "column",
  },
})(ModalVideoconferenciaURL);
