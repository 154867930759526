import React, { Fragment, useState } from "react";
import useSWR from "swr";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import MissedVideoCall from "../../../../_common/icones/MissedVideoCall";
import VideoCameraFront from "../../../../_common/icones/VideoCameraFront";
import ModalVideoconferenciaURL from "./ModalVideoconferenciaURL";
import { ActionButton } from "../../../../components";
import permissions from "../../../../_common/permissions";

VideoconferenciaURL.propTypes = {
  callback: PropTypes.func,
  vendedor: PropTypes.bool,
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  lead: PropTypes.object,
};

function VideoconferenciaURL({ callback = () => null, lead = {}, vendedor, ...props }) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const { data: possuiVideoconferencia } = useSWR(
    `/api/pipeline/Videoconferencia/PossuiVideoconferencia?leadId=${lead.id}`
  );

  const close = response => {
    setModalOpen(false);
    callback(response);
  };

  return (
    <Fragment>
      <ActionButton
        id={vendedor ? "btn-reenviar-url-vendedor" : "btn-reenviar-url-lead"}
        onClick={() => setModalOpen(true)}
        type="menu"
        icon={<SvgIcon>{vendedor ? <MissedVideoCall /> : <VideoCameraFront />}</SvgIcon>}
        label={
          vendedor
            ? intl.formatMessage({ defaultMessage: "Reenviar convite vendedor" })
            : intl.formatMessage({ defaultMessage: "Reenviar convite Lead" })
        }
        claim={permissions.VISUALIZAR_DETALHE && possuiVideoconferencia}
        leads={lead}
        {...props}
      />
      <Dialog open={modalOpen} onClose={() => close(false)}>
        <ModalVideoconferenciaURL vendedor={vendedor} id={lead.id} {...props} callback={close} />
      </Dialog>
    </Fragment>
  );
}

export default VideoconferenciaURL;
