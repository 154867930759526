import React from "react";
import { Formik, Form } from "formik";
import { useIntl } from "react-intl";
import { Button, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";

import { createSnackbarAPIException, createDialogConfirm, createSnackbar, AjaxBlackout } from "../../../../_common";
import { FormikSelectVendedor } from "../../../../components/BuscaAvancada/SelectVendedor";
import FeedbackRollout from "../../../../components/FeedbackRollout";
import useConfirmarTransferencia from "./useConfirmarTransferencia";
import { TIPO_GATE } from "../../../../_common/constantes";

ModalTransferirVendedor.propTypes = {
  callback: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  leads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      etapa: PropTypes.shape({ id: PropTypes.number, gateAnterior: PropTypes.number }),
      vendedorId: PropTypes.number,
      reuniao: PropTypes.object,
    })
  ),
};

const QTD_MIN_TRANSFERIR_EM_LOTE = 50;
export default function ModalTransferirVendedor({ leads, callback, onClose, onConfirm = x => x }) {
  const intl = useIntl();
  const ids = leads.map(lead => lead.id);
  const formikRef = React.createRef();
  const callbackValidarAgendamento = async (accepted, values) => {
    if (accepted) transferirVendedor(values);
  };

  const mandarParaFila = ids.length > 1 && leads.some(lead => lead.etapa?.gateAnterior === TIPO_GATE.AGENDAMENTO);
  const transferirEmLote = ids.length >= QTD_MIN_TRANSFERIR_EM_LOTE;

  const { confirmarTransferencia } = useConfirmarTransferencia({
    ids,
    onclose,
    transferirVendedorLote,
  });

  function transferirVendedorLote() {
    AjaxBlackout.Show();
    axios.post(`/api/pipeline/lead/enviarParaTransferenciaVendedor`, {
      leadIds: ids,
      vendedorId: formikRef.current.values.vendedorId,
    });
    setTimeout(() => {
      createSnackbar(
        intl.formatMessage({
          defaultMessage:
            "Transferência enviada para processamento. Aguarde, em breve a sua solicitação será concluída.",
        })
      );
      onConfirm(true);
      onClose();
      callback();
      AjaxBlackout.Hide();
    }, 10000);
  }

  const validarAgendamento = async values => {
    if (ids.length === 1) {
      AjaxBlackout.Show();
      const { data: reuniaoConflitante } = await axios.post(`/api/pipeline/Reuniao/ReuniaoConflitante`, {
        reuniaoId: leads[0].reuniao?.id,
        vendedorId: Number(values.vendedorId),
      });
      AjaxBlackout.Hide();
      if (reuniaoConflitante) {
        createDialogConfirm({
          text: intl.formatMessage({
            defaultMessage:
              "O vendedor selecionado já possui um agendamento na mesma data/hora que o Lead a transferir. Deseja continuar?",
          }),
          title: intl.formatMessage({ defaultMessage: "Conflito de reunião" }),
          acceptLabel: intl.formatMessage({ defaultMessage: "Confirmar" }),
          cancelLabel: intl.formatMessage({ defaultMessage: "Cancelar" }),
          tamanhoMaximo: "md",
          fullWidth: true,
          callback: accepted => callbackValidarAgendamento(accepted, values),
        });
      } else transferirVendedor(values);
    } else transferirVendedor(values);
  };

  const transferirVendedor = async values => {
    AjaxBlackout.Show();
    if (transferirEmLote || mandarParaFila) transferirVendedorLote(Number(values.vendedorId));
    else {
      try {
        const { data: success } = await axios.post(`/api/pipeline/Lead/TransferirVendedor`, {
          leadids: ids,
          vendedorId: Number(values.vendedorId),
        });
        callback(success);
      } catch (e) {
        createSnackbarAPIException(e);
        callback(false);
      } finally {
        AjaxBlackout.Hide();
      }
    }
  };

  const validate = values => {
    const errors = {};
    if (!values.vendedorId) errors.vendedorId = intl.formatMessage({ defaultMessage: "Campo obrigatório" });
    if (leads.length === 1 && values.vendedorId === leads[0].vendedorId)
      errors.vendedorId = intl.formatMessage({ defaultMessage: "O vendedor informado deve ser diferente" });
    return errors;
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={transferirEmLote ? confirmarTransferencia : validarAgendamento}
      innerRef={formikRef}
      validate={validate}
    >
      <Form>
        <DialogTitle>{intl.formatMessage({ defaultMessage: "Transferir vendedor" })}</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText>
            {ids.length > 1
              ? intl.formatMessage({
                  defaultMessage:
                    "Caso ocorra conflito de reunião com a agenda do vendedor selecionado, o mesmo será notificado por e-mail.",
                })
              : null}
          </DialogContentText>
          <FormikSelectVendedor
            name="vendedorId"
            fullWidth
            type="singular"
            id="transferir-vendedor"
            enableSearch
            searchLabel={intl.formatMessage({ defaultMessage: "Pesquisar por vendedor" })}
            truncateDescriptionAt={50}
            url="/api/pipeline/reuniao/buscaVendedores"
          />
        </DialogContent>
        <DialogActions>
          <FeedbackRollout classe="feedback-trasnf-vendedor" />
          <Button onClick={onClose}> {intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
          <Button type="submit" color="primary">
            {intl.formatMessage({ defaultMessage: "Confirmar" })}
          </Button>
        </DialogActions>
      </Form>
    </Formik>
  );
}
