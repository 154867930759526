import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Divider, withStyles, Tooltip, Typography, IconButton } from "@material-ui/core";
import FindInPage from "@material-ui/icons/FindInPage";
import { connect } from "react-redux";
import { compose } from "recompose";
import useSWR from "swr";
import { useIntl } from "react-intl";
import LaunchIcon from "@material-ui/icons/Launch";
import EditIcon from "@material-ui/icons/Edit";

import { Flex, Tipografia } from "../../../../_common";
import { Fone, HoverActions } from "../../../../components";
import RowEmail from "./components/RowEmail";
import { ESTADO_MINERACAO, LABELS_BASE_LEGAL } from "../../../../_common/constantes";
import BuscadorEmail from "./components/BuscadorEmail";
import { formatPhoneMask } from "../../../../_common/utils/phone";
import { buscarListaContatos, salvarListaContatos } from "../../../../store/reducers/detalhesLead/detalhesLeadActions";
import { isValidHttpUrl, normalizeLinkProtocol } from "../../../../_common/utils/string";
import { usePermission, useTipoPlanoSpotter } from "../../../../hooks";
import PERMISSIONS from "../../../../_common/permissions";

LeadContatos.propTypes = {
  classes: PropTypes.object,
  leadId: PropTypes.string,
  listaContatos: PropTypes.array,
  lead: PropTypes.object,
  setModalLigacaoIsOpen: PropTypes.func,
  setContatoSelecionado: PropTypes.func,
  setIsModalEditarContatoOpen: PropTypes.func,
  limiteEdicaoMultiplaContatos: PropTypes.number,
};

const mapDispatchToProps = dispatch => ({
  buscarLista: id => dispatch(buscarListaContatos(id)),
  salvarLista: contato => dispatch(salvarListaContatos(contato)),
});

const mapStateToProps = state => ({
  listaContatos: state.detalhesLead.contatos,
});

function LeadContatos({
  classes,
  lead,
  leadId,
  listaContatos,
  setModalLigacaoIsOpen,
  setContatoSelecionado,
  setIsModalEditarContatoOpen,
  limiteEdicaoMultiplaContatos,
}) {
  const { data: creditos } = useSWR("/api/pipeline/FuncionalidadePlano/CreditosBuscador");
  const { data: protecaoDados } = useSWR("/api/pipeline/protecaoDados/buscar");
  const intl = useIntl();
  const podeMinerar = usePermission(PERMISSIONS.ENCONTRARCONTATOS, lead);
  const isSpotterFull = useTipoPlanoSpotter();

  const openModal = contato => {
    setContatoSelecionado(contato);
    setIsModalEditarContatoOpen(true);
  };

  return (
    <Fragment>
      {!!listaContatos?.length &&
        listaContatos.map((contato, index) => (
          <Flex key={contato.id} flexDirection="column">
            {!!index && <Divider className={classes.divider} />}

            <Flex justifyContent="space-between" className={classes.root}>
              <Flex flexDirection="column-reverse">
                <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.sideSpace}>
                  {contato.cargo}
                </Tipografia>
                <Tipografia tipo="textoSidebar" className={classes.spaced}>
                  {contato.nome}
                </Tipografia>
              </Flex>

              <Flex alignItems="flex-end">
                {listaContatos.length > limiteEdicaoMultiplaContatos && (
                  <IconButton
                    id="btn-editar-contato"
                    onClick={() => openModal(contato)}
                    className={classes.buttonIconEdit}
                  >
                    <EditIcon className={classes.icon} />
                  </IconButton>
                )}

                {isSpotterFull && (
                  <Flex alignItems="flex-end" flexDirection="column-reverse">
                    {contato.emails[0] &&
                      contato.mineracao !== ESTADO_MINERACAO.MINERADO && (
                        <Fragment>
                          <Tooltip
                            title={intl.formatMessage({
                              defaultMessage: "Busca de e-mails indisponível, pois há e-mail cadastrado",
                            })}
                          >
                            <FindInPage className={classNames([classes.iconDisabled, classes.icon])} />
                          </Tooltip>
                        </Fragment>
                      )}

                    {!contato.emails[0] && (
                      <Flex>
                        {contato.mineracao === ESTADO_MINERACAO.SEM_MINERACAO &&
                          podeMinerar && (
                            <div>
                              <BuscadorEmail creditos={creditos} contato={contato} leadId={leadId} lead={lead} />
                            </div>
                          )}

                        {contato.mineracao === ESTADO_MINERACAO.EM_PROGRESSO && (
                          <Typography color="textSecondary">
                            {intl.formatMessage({ defaultMessage: "BUSCANDO..." })}
                          </Typography>
                        )}

                        {contato.mineracao === ESTADO_MINERACAO.MINERADO && (
                          <Tooltip
                            title={intl.formatMessage({
                              defaultMessage: "Não foram encontrados e-mails",
                            })}
                          >
                            <FindInPage className={classNames([classes.iconDisabled, classes.icon])} />
                          </Tooltip>
                        )}
                      </Flex>
                    )}
                  </Flex>
                )}
              </Flex>
            </Flex>

            {contato.telefones.map(telefone => (
              <Fone
                value={formatPhoneMask({ ddi: telefone.ddi, phone: telefone.numero })}
                ddi={telefone.ddi}
                leadId={leadId}
                tipo={String(telefone.tipo.id)}
                key={telefone.id}
                setModalLigacaoIsOpen={setModalLigacaoIsOpen}
              />
            ))}
            {contato.emails.map(email => (
              <RowEmail
                emailInfo={{
                  name: contato.nome,
                  email: email.endereco,
                  id: email.id,
                  valido: email.valido,
                  validando: email.validando,
                  mineracao: email.mineracao,
                  probabilidadeEntrega: email.probabilidadeEntrega,
                }}
                contato={{ ...contato }}
                key={email.id}
              />
            ))}
            {contato.mensageiros.map(mensageiro => (
              <HoverActions key={mensageiro.id} value={mensageiro.usuario}>
                {isValidHttpUrl(mensageiro.usuario) && (
                  <Tooltip
                    title={intl.formatMessage({
                      defaultMessage: "Abrir",
                    })}
                    placement="bottom"
                  >
                    <LaunchIcon
                      onClick={() => window.open(normalizeLinkProtocol(mensageiro.usuario), "_blank")}
                      color="primary"
                    />
                  </Tooltip>
                )}
              </HoverActions>
            ))}

            {protecaoDados && (
              <Flex flexDirection="column">
                <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.baseLegalLabel}>
                  {intl.formatMessage({ defaultMessage: "Base legal para coleta dos dados" })}
                </Tipografia>

                {contato.coletaDados ? (
                  <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.baseLegalEnum}>
                    {LABELS_BASE_LEGAL[contato.coletaDados]?.defaultMessage}
                  </Tipografia>
                ) : (
                  <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.italic}>
                    {intl.formatMessage({ defaultMessage: "Não informado" })}
                  </Tipografia>
                )}
                <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.baseLegalLabel}>
                  {intl.formatMessage({ defaultMessage: "Base legal para envio de comunicação" })}
                </Tipografia>
                {contato.envioComunicacao ? (
                  <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.baseLegalEnum}>
                    {LABELS_BASE_LEGAL[contato.envioComunicacao]?.defaultMessage}
                  </Tipografia>
                ) : (
                  <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.italic}>
                    {intl.formatMessage({ defaultMessage: "Não informado" })}
                  </Tipografia>
                )}
                <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.baseLegalLabel}>
                  {intl.formatMessage({ defaultMessage: "Base legal para coleta de dados de voz" })}
                </Tipografia>
                {contato.dadosVoz ? (
                  <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.baseLegalEnum}>
                    {LABELS_BASE_LEGAL[contato.dadosVoz]?.defaultMessage}
                  </Tipografia>
                ) : (
                  <Tipografia tipo="notaLegendas" cor="darkSecondaryText" className={classes.italic}>
                    {intl.formatMessage({ defaultMessage: "Não informado" })}
                  </Tipografia>
                )}
              </Flex>
            )}
          </Flex>
        ))}
      {!listaContatos?.length && (
        <Tipografia cor="darkSecondaryText" italico style={{ padding: 20 }}>
          {intl.formatMessage({ defaultMessage: "Nenhum contato cadastrado" })}
        </Tipografia>
      )}
    </Fragment>
  );
}

const enhance = compose(
  withStyles(theme => ({
    italic: { fontStyle: "italic", padding: "0 20px 6px 28px" },
    baseLegalLabel: { padding: "12px 20px 6px 20px" },
    baseLegalEnum: { padding: "0 20px 6px 28px" },
    contato: { width: "100%", paddingBottom: 20 },
    divider: { margin: "8px 20px" },
    labelPrincipal: { padding: "0 20px 8px" },
    spaced: { padding: "0 20px" },
    sideSpace: { paddingLeft: 20, paddingRight: 20 },
    icon: { height: 21, width: 21 },
    buttonIconEdit: { padding: 0, paddingRight: 12 },
    iconDisabled: { color: "rgba(0,0,0,0.54)" },
    loader: { height: "16px !important", width: "16px !important", marginRight: 2, marginLeft: 2 },
    root: {
      padding: "8px 20px",
      "&:hover": {
        backgroundColor: theme.palette.backgroundRowHover,
      },
    },
  })),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(LeadContatos);
